import React, { useRef } from "react";
// import "./styles.css";
import { useCallback, useEffect, useState } from "react";
import lottie from "lottie-web";
import FinkAtomJson from "./finkAtom.json";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

export default function FinkAtom(props) {
  const [jsonData, setJsonData] = useState(null);
  const [scale, setScale] = useState(1);
  const [zoomIn, setZoomIn] = useState(true);
  const timeoutRef = useRef(null);

  const [isSpeaking, setIsSpeaking] = useState(false);

  const subscriptionKey = "02f2f1db65794856acb9ab9ca09fdeae";
  const serviceRegion = "eastus";
  const voiceName = "en-US-AvaNeural"; // Example voice name

  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
    subscriptionKey,
    serviceRegion
  );
  speechConfig.speechSynthesisVoiceName = voiceName;
  const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
  const synthesizer = new SpeechSDK.SpeechSynthesizer(
    speechConfig,
    audioConfig
  );

  const startTimeout = (timeoutSeconds) => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // Set a new timeout
    timeoutRef.current = setTimeout(props.goNext, parseInt(timeoutSeconds));
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setScale((prevScale) => {
        if (prevScale >= 2) {
          setZoomIn(false);
        } else if (prevScale <= 1) {
          setZoomIn(true);
        }
        return zoomIn ? prevScale + 0.01 : prevScale - 0.01;
      });
    }, 12); // Adjust the interval for a smoother or faster zoom

    return () => clearInterval(interval);
  }, [zoomIn]);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("111"), // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: FinkAtomJson,
    });
  }, [jsonData]);

  useEffect(() => {
    // const AudioContext = window.AudioContext || window.webkitAudioContext;
    // const audioContext = new AudioContext();

    // const silentAudioBuffer = audioContext.createBuffer(1, 1, 22050);
    // const source = audioContext.createBufferSource();
    // source.buffer = silentAudioBuffer;
    // source.connect(audioContext.destination);
    // source.start(0);
    // const checkAudioContextState = setInterval(() => {
    //   if (audioContext.state === "running") {
    //     clearInterval(checkAudioContextState);
    //     speakText();
    //   }
    // }, 100);

    // return () => {
    //   clearInterval(checkAudioContextState);
    // };
    speakText();
  }, [props.text]);

  const speakText = () => {
    console.log("speaking", props.text);

    synthesizer.speakTextAsync(
      props.text,
      (result) => {
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          const durationInSeconds = result.privAudioDuration / 10000;
          startTimeout(durationInSeconds);

          console.log("Synthesis completed.");
        } else {
          console.error("Speech synthesis canceled, " + result.errorDetails);
        }
        setIsSpeaking(false);
      },
      (error) => {
        console.error(error);
        setIsSpeaking(false);
      }
    );
  };

  return (
    <div
      style={{
        width: 40,
        height: 40,
        transform: `scale(${scale})`,
        transformOrigin: "center",
      }}
    >
      <div style={{ width: 40, height: 40 }} id={"111"} onClick={speakText} />
    </div>
  );
}
