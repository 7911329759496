import firebase from "firebase/compat/app"; // Note the 'compat' part here
import "firebase/compat/firestore";

if (!firebase?.apps?.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyD33JyUFjugVISjRu_yElDamtvR0-6tBlU",
    authDomain: "prototype-finkraft.firebaseapp.com",
    projectId: "prototype-finkraft",
    storageBucket: "prototype-finkraft.appspot.com",
    messagingSenderId: "283994867558",
    appId: "1:283994867558:web:d5038e6f45625e3c86eaa7",
    measurementId: "G-6J2FHW78VS",
  });
}

export default firebase.firestore();
