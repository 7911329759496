import ProtectedRoute from "app/utils/ProtectedRoute";
import AppTour from "./AppTour";
import Greeting from "./components/Greeting";

//eslint-disable-next-line
export default {
  path: "/tour/",
  element: <AppTour />,
  strict: true,
  children: [
    {
      path: "/tour/start",
      element: <Greeting />,
      exact: true,
    },
  ],
};
