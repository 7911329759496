import Typography from "app/shared/Typography";
import "./AddIntegration.scss";
import TextField from "app/shared/TextField";
import { Button, Select, Tag, message } from "antd";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import connectLottie from "static/lottie/connect.json";
import {
  API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE,
  API_ENDPOINT_AIRLINE_CREDENTIAL_UPDATE,
} from "app/scenes/Credentials/credentials.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: connectLottie,
};

const integrationMap: any = {
  GST: {
    logo: "https://www.indiapost.gov.in/_layouts/15/images/DOP.Portal.UILayer/Emblem_of_India.svg",
    title: "GST Portal Authentication",
  },
  AIRLINE: {
    logo: "https://static.vecteezy.com/system/resources/previews/014/576/653/original/airplane-icon-in-blue-circle-png.png",
    title: "Airline Authentication",
  },
  EMAIL: {
    logo: "https://cdn-icons-png.freepik.com/512/6711/6711567.png",
    title: "Email Authentication",
  },
};

export default function AddAirIntegration(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [portalId, setPortalID] = useState("");
  const [portalPassword, setPortalPassword] = useState("");
  const [otpEmail, setOTPEMail] = useState("");
  const [otpPassword, setOTPPassword] = useState("");
  const [panNo, setPanNo] = useState("");

  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  useEffect(() => {
    if (props.editMode) {
      console.log("its a edit");

      setPortalID(props.data?.portal_id || "");
      setPortalPassword(props.data?.portal_pass || "");
      setOTPEMail(props.data?.otp_email || "");
      setPortalPassword(props.data?.otp_pass || "");
      setPanNo(props.data?.panNo || "");
    } else {
      console.log("coming here");
      setPortalID("");
      setPortalPassword("");
      setOTPEMail("");
      setPortalPassword("");
      setPanNo("");
    }
  }, [props.data]);

  const handleAddAirIntegration = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE, {
      ...props.data,
      portal_id: portalId,
      portal_pass: portalPassword,
      otp_email: otpEmail,
      otp_pass: otpPassword,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleUpdateAirIntegration = async () => {
    setLoading(true);
    const response = await apiPut(API_ENDPOINT_AIRLINE_CREDENTIAL_UPDATE, {
      ...props.data,
      portal_id: portalId,
      portal_pass: portalPassword,
      otp_email: otpEmail,
      otp_pass: otpPassword,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  return (
    <div className="AddIntegration">
      {isLoading ? (
        <>
          <Lottie options={lottieOptions} height={160} width={160} />
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.600") }}
          >
            *do not close this window, as we are processing your request
          </Typography>
        </>
      ) : (
        <>
          <div className="IntegrationImage">
            <img
              src={integrationMap.AIRLINE.logo}
              alt="integrationImg"
              style={{ width: 44 }}
            />
            <Typography variant="h6" style={{ marginTop: 24 }}>
              {props.data?.airline_name}
            </Typography>
            <Tag color="green" style={{ marginTop: 6 }}>
              PAN - {props.data?.pan}
            </Tag>
          </div>
          <div className="FormBody">
            <div className="FormGroup">
              <TextField
                placeholder="Portal ID"
                style={{ height: 42 }}
                onChange={(e: any) => setPortalID(e.target.value)}
                defaultValue={portalId}
              />
            </div>
            <div className="FormGroup">
              <TextField
                placeholder="Portal Password"
                style={{ height: 42 }}
                onChange={(e: any) => setPortalPassword(e.target.value)}
                defaultValue={portalPassword}
              />
            </div>

            <div className="FormGroup">
              <TextField
                placeholder="OTP Email"
                style={{ height: 42 }}
                onChange={(e: any) => setOTPEMail(e.target.value)}
                defaultValue={otpEmail}
              />
            </div>

            <div className="FormGroup">
              <TextField
                placeholder="OTP Password"
                style={{ height: 42 }}
                onChange={(e: any) => setOTPPassword(e.target.value)}
                defaultValue={otpPassword}
              />
            </div>

            <div className="FormGroup">
              <Button
                size="large"
                style={{
                  width: "100%",
                  background: colorPicker("primary.700"),
                  color: "white",
                }}
                onClick={
                  props.editMode
                    ? handleUpdateAirIntegration
                    : handleAddAirIntegration
                }
              >
                {props.editMode ? "Update Integration" : "Authenticate"}
              </Button>
            </div>
          </div>{" "}
        </>
      )}
      {props.type === "GST" && !props.editMode ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Typography
            style={{ color: colorPicker("primary.700"), cursor: "pointer" }}
            variant="caption"
            onClick={props.showBulk}
          >
            Or setup bulk integrations
          </Typography>
        </div>
      ) : null}

      {contextHolder}
    </div>
  );
}
