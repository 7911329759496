import { Button, Tag } from "antd";
import { ArrowRightOutlined, CheckOutlined } from "@ant-design/icons";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { colorPicker } from "app/utils/color.helper";
import onboaringIllustration from "static/images/illustration/onboaringIllustration.png";
import Typography from "app/shared/Typography";
import color from "theme/color";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import VisibilityIcon from "@mui/icons-material/Visibility";
export default function Greeting(props: any) {
  return (
    <div className="Greeting" style={{ padding: "20px 28px" }}>
      <div style={{ borderBottom: "1px solid #dbdbdb", paddingBottom: 16 }}>
        <Typography style={{ fontSize: 26 }}>
          Revolutionize Your ITC Journey Welcome Aboard!
        </Typography>
      </div>

      <div className="SolutionView">
        <div className="SolutionCard" id="solcard1">
          <div className="SolCheck">
            <InsightsIcon style={{ color: "white", fontSize: 20 }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            GST ITC Analytics
          </Typography>
          <div>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Complete GST ITC Analysis
            </Typography>

            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Analyses flight, Hotel, CHA and more.
            </Typography>

            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Maximise Input tax credit you claim
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Identify missing records
            </Typography>
          </div>
        </div>

        <div className="SolutionCard" id="solcard2">
          <div className="SolCheck">
            <QueryStatsIcon style={{ color: "white", fontSize: 20 }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Reconcilation
          </Typography>

          <div>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              With excellent accuracy
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Ensuring regulatory compliance
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Helps in financial record integrity
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Automations and alerts
            </Typography>
          </div>
        </div>

        <div className="SolutionCard" id="solcard3">
          <div className="SolCheck">
            <ConnectWithoutContactIcon
              style={{ color: "white", fontSize: 22 }}
            />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Easy Follow Ups
          </Typography>

          <div>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              AI led emails to get missing records
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Improve communication and collaboration
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Tracking and reporting
            </Typography>

            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Data driven actions
            </Typography>
          </div>
        </div>

        <div className="SolutionCard" id="solcard4">
          <div className="SolCheck">
            <VisibilityIcon style={{ color: "white", fontSize: 20 }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Complete Visibility
          </Typography>

          <div>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Comprehensive ITC Analytics
            </Typography>

            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Customized Dashboards
            </Typography>
            <Typography
              variant="xs"
              style={{ marginTop: 12, color: colorPicker("neutral.700") }}
            >
              <CheckOutlined
                style={{
                  marginRight: 6,
                  color: colorPicker("green.700"),
                }}
              />
              Bird-eye view on everything
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 32, width: 200 }}>
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={props.goNext}
          style={{ width: "100%" }}
        >
          Let's Go
        </Button>
        <Typography
          style={{
            color: "#736d79",
            marginTop: 4,
            fontSize: 12,
          }}
        >
          Setup less than a minute
        </Typography>
      </div>

      {/* <div
        style={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <Typography style={{ marginTop: 10, fontSize: 16 }}>
            Ready to put your ITC journey on autopilot?
          </Typography>

          <div style={{ marginTop: 14 }}>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Optimise ITC claimed</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Set payment terms</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Improve vendor filling behavior</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Sync with your ERP</Typography>
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={props.goNext}
            >
              Let's Go
            </Button>
            <Typography
              style={{
                color: "#736d79",
                marginTop: 4,
                fontSize: 12,
              }}
            >
              Setup less than a minute
            </Typography>
          </div>
        </div>
      </div> */}
    </div>
  );
}
