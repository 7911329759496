import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";

import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined, CopyOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import { Avatar, Button, Empty, Modal, Spin, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import {
  API_ENDPOINT_SETTING_MEMBERS_LIST,
  API_ENDPOINT_SETTING_WORKSPACE_LIST,
} from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import TextField from "app/shared/TextField";
import InviteMember from "../InviteMember";
export default function MemberSetting(props: any) {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const gridRef = useRef<any>(null);

  const copyDataToBoard = (text: string) => {
    navigator?.clipboard?.writeText(text);
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const initialColDefs = [
    {
      field: "name",
      headerName: "Name",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "workspace",
      headerName: "Associated Workspace",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <WorkspacesOutlinedIcon style={{ fontSize: 18, marginRight: 4 }} />
            {params?.data?.workspace.length}
          </div>
        );
      },
    },

    {
      field: "pasword",
      headerName: "Temporary Password",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return params?.data?.password ? (
          <Tag
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => copyDataToBoard(params?.data?.password)}
          >
            <CopyOutlined />
            <Typography
              variant="xs"
              style={{ color: colorPicker("neutral.700"), marginLeft: 4 }}
            >
              Copy Password
            </Typography>
          </Tag>
        ) : null;
      },
    },
  ];
  useEffect(() => {
    setColumnDefs(initialColDefs);
  }, []);
  useEffect(() => {
    fetchUsersList();
  }, [userDetails.currentWorkspace]);
  const fetchUsersList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTING_MEMBERS_LIST);
    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  const handleMemberShipSuccess = () => {
    setShowModal(false);
    fetchUsersList();
  };

  const handleSearchWorkspace = (query: string) => {
    console.log("values is:L", query);
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  return (
    <div className="ScreenContainer">
      <PageHeader
        title="Members"
        rightActions={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 12 }}>
              <SearchInput
                placeholder="Search members"
                onSearch={handleSearchWorkspace}
              />
            </div>
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
            >
              Add Members
            </Button>
          </div>
        }
      />
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            // onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
            autoResize
            showStatusBar
          />
        )}
      </div>

      <Modal
        title="Add Members"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <InviteMember
          onClose={() => setShowModal(false)}
          onSuccess={handleMemberShipSuccess}
        />
      </Modal>
      {contextHolder}
    </div>
  );
}
