import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Typography from "../Typography";
import { colorPicker } from "app/utils/color.helper";
const Loader = (props: any) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 48,
          }}
          spin
        />
      }
    />
    {props.title ? (
      <Typography style={{ marginTop: 24, color: colorPicker("neutral.700") }}>
        {props.title}
      </Typography>
    ) : null}
  </div>
);
export default Loader;
