import { useEffect, useState } from "react";
import "./Settings.scss";
import { ConfigProvider, Steps } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

export default function Settings(props) {
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const navigate = useNavigate();

  return (
    <div className="Settings">
      <Outlet />
    </div>
  );
}
