import React, { useState, useCallback } from "react";
import { Select, Spin, Tag } from "antd";
import debounce from "lodash/debounce";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_SEARCH_MEMBERS } from "app/scenes/Settings/settings.constants";
import Typography from "../Typography";
import { colorPicker } from "app/utils/color.helper";
import { MailOutlined } from "@ant-design/icons";
const { Option } = Select;

const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const DebouncedSelect = (props: any) => {
  const [options, setOptions] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);

  const fetchOptions = async (search: string) => {
    if (!search) {
      setOptions([]);
      return;
    }
    setFetching(true);
    try {
      const response = await apiGet(
        `${API_ENDPOINT_SETTING_SEARCH_MEMBERS}?email=${search}`
      );
      if (response.data && response.data.length > 0) {
        setOptions(response.data);
      } else {
        if (validateEmail(search)) {
          setOptions([{ name: "EXTERNAL", email: search, id: search }]);
        }
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setFetching(false);
    }
  };

  const debounceFetcher = useCallback(debounce(fetchOptions, 300), []);

  const handleSelect = (value: any) => {
    const selectedOption = options.find((option) => option.id === value);
    props.onOptionSelect && props.onOptionSelect(value, selectedOption);
  };
  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      style={{ width: "100%" }}
      placeholder="Search Member"
      value={""} // Bind the value state to the select component
      onSelect={handleSelect} // Handle the option selection
    >
      {options.map((option: any) => (
        <Option key={option.id} value={option.id}>
          {option.name === "EXTERNAL" ? (
            <Tag
              style={{ marginBottom: 3, lineHeight: 1, padding: 2 }}
              color="red"
            >
              EXTERNAL
            </Tag>
          ) : (
            <Typography variant="caption">{option.name}</Typography>
          )}
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700") }}
          >
            <MailOutlined style={{ marginRight: 4 }} />
            {option.email}
          </Typography>
        </Option>
      ))}
    </Select>
  );
};

export default DebouncedSelect;
