import { colorPicker } from "app/utils/color.helper";
import FinkAtom from "../FinkAtom";
import Typography from "../Typography";

export default function TourContent(props: any) {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {props.speak ? (
        <FinkAtom
          //@ts-ignore
          text={props.speak}
          goNext={props.goNext}
        />
      ) : null}
      <Typography
        style={{
          marginTop: 24,
          color: colorPicker("neutral.800"),
        }}
        variant="caption"
      >
        {props.text}
      </Typography>
    </div>
  );
}
