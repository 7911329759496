import { Button, Checkbox, Divider, Form, Spin, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_OAUTH_VERIFY_CODE,
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_GET_USER_INFO,
} from "../../auth.constants";

import { LoadingOutlined } from "@ant-design/icons";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";
import Loader from "app/shared/Loader";

type FieldType = {
  email?: string;
  password?: string;
};

export default function OAuthCallback(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  let { token } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    verifyCode(token || "");
  }, [token]);
  const verifyCode = async (codeValue: string) => {
    const response = await apiGet(
      API_ENDPOINT_AUTH_OAUTH_VERIFY_CODE + codeValue
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
    }
  };

  return (
    <div className="AuthForm">
      <div className="FieldContainer" style={{ marginTop: 0, height: "40vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              color: colorPicker("neutral.900"),
            }}
            variant="h5"
          >
            Authorizing....
            <Spin
              style={{ marginLeft: 12 }}
              indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
            />
          </Typography>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
