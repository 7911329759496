import PageHeader from "app/shared/PageHeader";
import "../../FinkAnalytics.scss";
import { Button, Checkbox, Input, Modal, Select, Switch, Tag } from "antd";
import SearchTable from "../SearchTable";
import { useEffect, useState } from "react";
import { AgTableClient } from "app/shared/AgTable";
import { EyeOutlined } from "@ant-design/icons";
import { apiGet } from "app/services/apiServices";
import {
  API_ENDPOINT_ZA_TABLE_GET_SCHEMA_LIST,
  API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF,
} from "../../finkanalytics.constants";
import Loader from "app/shared/Loader";
import { useNavigate } from "react-router-dom";

export default function SchemaList(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchSchemaList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_ZA_TABLE_GET_SCHEMA_LIST);

    setTableData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSchemaList();
  }, []);

  const navigate = useNavigate();

  const handleUpdateSchemaMeta = () => {};
  const columnDefs = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "moduleId",
      headerName: "Module ID",
      width: 150,
      suppressMenu: true,
      formatType: "CHIP",
    },

    {
      field: "createdAt",
      headerName: "Created At",
      suppressMenu: true,
      width: 200,
      formatType: "DATE",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated At",
      suppressMenu: true,
      width: 200,
      formatType: "DATE",
    },
    {
      field: "action",
      headerName: "Action",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => (
        <Button
          size="small"
          icon={<EyeOutlined />}
          onClick={() => navigate("/fa/schema/edit/" + params?.data?.viewId)}
        >
          View Schema
        </Button>
      ),
    },
  ];

  return (
    <div className="FASetup">
      <div className="Header">
        <PageHeader title="Fink Analytics " />
      </div>
      {/* <div className="SearchBoxContainer">
        <SearchTable
          placeholder="Search ZA Table"
          style={{ width: 400, height: 40 }}
          onChange={handleSearch}

        />
      </div> */}
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            hideToolbar
            autoResize
          />
        )}
      </div>
    </div>
  );
}
