import Typography from "app/shared/Typography";
import "./AddIntegration.scss";
import TextField from "app/shared/TextField";
import { Button, Select, message } from "antd";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import connectLottie from "static/lottie/connect.json";
import {
  API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_GST_ADD_GSTINS,
  API_ENDPOINT_GST_ADD_PAN,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE,
  API_ENDPOINT_GST_CREDENTIAL_UPDATE,
} from "app/scenes/Credentials/credentials.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_CHECK_CREDS_LOGIN } from "app/scenes/AppTour/onboarding.constants";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: connectLottie,
};

const integrationMap: any = {
  GST: {
    logo: "https://miro.medium.com/v2/resize:fit:1400/1*9mkbjH3YUUGDSWk2UXFghw.png",
    title: "GST Portal Authentication",
  },
  AIRLINE: {
    logo: "https://static.vecteezy.com/system/resources/previews/014/576/653/original/airplane-icon-in-blue-circle-png.png",
    title: "Airline Authentication",
  },
  EMAIL: {
    logo: "https://cdn-icons-png.freepik.com/512/6711/6711567.png",
    title: "Email Authentication",
  },
};

export default function AddIntegration(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailList, setEmailList] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  console.log("payload props", props);
  const fetchEmailsForCluster = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST);
    if (response.status) {
      setEmailList(response.data);
    } else {
    }
  };

  useEffect(() => {
    if (props.editMode) {
      setUsername(props.data?.username || "");
      setPassword(props.data?.password || "");
    } else {
      setUsername("");
      setPassword("");
    }
  }, [props.data]);

  const handleAddPanNumber = async () => {
    setLoading(true);
    const responseGST = await apiPost(
      API_ENDPOINT_ONBOARDING_CHECK_CREDS_LOGIN,
      {
        username: username,
        password: password,
      }
    );
    console.log("gst info :", responseGST);
    if (!responseGST.success) {
      messageApi.error({
        type: "error",
        content: "Your credential is invalid",
      });
      setLoading(false);
      return;
    }

    try {
      let gstinNumber = responseGST?.api_response.gstin;
      // await apiPost(API_ENDPOINT_GST_ADD_PAN, {
      //   pan: gstinNumber.substring(2, 12),
      //   entity_name: "",
      //   workspace_id: userDetails?.currentWorkspace?.id,
      // });
      handleAddIntegration({
        gstin: gstinNumber,
        username: username,
        password: password,
      });
    } catch (error) {
      console.log("could not add pan");
    }
  };

  const handleAddGstins = async (jsonData: any) => {
    await apiPost(API_ENDPOINT_GST_ADD_GSTINS, {
      gstin: jsonData.gstin,
      entity_name: jsonData.bname,
      pan: jsonData.gstin.substring(2, 12),
    });

    handleAddIntegration(jsonData);
  };

  const handleAddIntegration = async (jsonData: any) => {
    setLoading(true);

    let payload = {
      gstin: jsonData.gstin,
      username: username,
      password: password,
    };
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE, {
      credentials: [payload],
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleAddAirIntegration = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE, {});
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleAddEmailIntegration = async () => {};

  const handleIntegrationAction = () => {
    switch (props.type) {
      case "GST":
        handleAddPanNumber();
        return;
      case "AIRLINE":
        handleAddAirIntegration();
        return;
      case "EMAIL":
        handleAddEmailIntegration();
        return;
      default:
        handleAddPanNumber();
        return;
    }
  };

  const updateGSTCredential = async () => {
    const response = await apiPut(API_ENDPOINT_GST_CREDENTIAL_UPDATE, {
      credentials: { ...props.data, username: username, password: password },
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleIntegrationActionUpdate = () => {
    switch (props.type) {
      case "GST":
        updateGSTCredential();
        return;
      case "AIRLINE":
        handleAddAirIntegration();
        return;
      case "EMAIL":
        handleAddEmailIntegration();
        return;
      default:
        handleAddPanNumber();
        return;
    }
  };

  console.log("default data:", props.data);
  return (
    <div className="AddIntegration">
      {isLoading ? (
        <>
          <Lottie options={lottieOptions} height={160} width={160} />
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.600") }}
          >
            *do not close this window, as we are processing your request
          </Typography>
        </>
      ) : (
        <>
          <div className="IntegrationImage">
            <img
              src={integrationMap[props.type].logo}
              alt="integrationImg"
              style={{ width: 44 }}
            />
            <Typography variant="h6" style={{ marginTop: 24 }}>
              {integrationMap[props.type].title}
            </Typography>
          </div>
          <div className="FormBody">
            {props.type === "EMAIL" ? (
              <div className="FormGroup">
                <Select
                  placeholder="Select Email Config"
                  style={{ width: "100%", height: 42 }}
                  options={emailList.map((emailItem: any, key: any) => {
                    return {
                      value: emailItem.email_login_pid,
                      label: emailItem.email,
                    };
                  })}
                />
              </div>
            ) : null}
            <div className="FormGroup">
              <TextField
                placeholder="Username"
                style={{ height: 42 }}
                onChange={(e: any) => setUsername(e.target.value)}
                defaultValue={username}
              />
            </div>
            <div className="FormGroup">
              <TextField
                placeholder="Password"
                style={{ height: 42 }}
                onChange={(e: any) => setPassword(e.target.value)}
                defaultValue={password}
              />
            </div>

            <div className="FormGroup">
              <Button
                size="large"
                style={{
                  width: "100%",
                  background: colorPicker("primary.700"),
                  color: "white",
                }}
                onClick={
                  props.editMode
                    ? handleIntegrationActionUpdate
                    : handleIntegrationAction
                }
              >
                {props.editMode ? "Update Integration" : "Authenticate"}
              </Button>
            </div>
          </div>{" "}
        </>
      )}
      {props.type === "GST" && !props.editMode && !isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Typography
            style={{ color: colorPicker("primary.700"), cursor: "pointer" }}
            variant="caption"
            onClick={props.showBulk}
          >
            Or setup bulk integrations
          </Typography>
        </div>
      ) : null}

      {contextHolder}
    </div>
  );
}
