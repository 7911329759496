import PageHeader from "app/shared/PageHeader";
import "../../Integrations.scss";

import { Badge, Empty, Modal, Segmented, Tabs, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import EmptyState from "app/shared/EmptyState";
import { useState } from "react";
import AddIntegration from "../AddIntegration";
import { AgTableClient } from "app/shared/AgTable";
import AddAirIntegration from "../AddAirIntegration";
import AirlineCredentials from "app/scenes/Credentials/components/AirlineCredentials";

const initialColDefs = [
  {
    field: "pan",
    headerName: "PAN",
    enableRowGroup: true,
    minWidth: 200,
  },
  {
    field: "gstin",
    headerName: "GSTIN",
    enableRowGroup: true,
    minWidth: 200,
  },
  {
    field: "username",
    headerName: "Username",
    enableRowGroup: true,
    minWidth: 200,
    editable: true,
  },

  {
    field: "password",
    headerName: "Password",
    enableRowGroup: true,
    minWidth: 200,
    editable: true,
  },
];

export default function AirlineIntegrations(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Table");
  const [cardData, setCardData] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);

  const handleSuccess = () => {
    setShowModal(false);
    props.loadData();
  };
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "12px 20px" }}>
        <Segmented
          options={["Table", "Tile"]}
          onChange={(value) => setActiveTab(value)}
        />
      </div>
      {activeTab === "Tile" ? (
        <div className="CardList">
          {/* <div
            className="IntegrationCard cardAdd"
            onClick={() => setShowModal(true)}
          >
            <PlusOutlined
              style={{ fontSize: 44, color: colorPicker("neutral.700") }}
            />
          </div> */}
          {props.data.map((creds: any, key: any) => {
            return (
              <div
                className="IntegrationCard"
                key={key}
                onClick={() => {
                  setCardData(creds);
                  setShowModal(true);
                  setEditMode(true);
                }}
              >
                <div className="CardTopContainer">
                  <div className="ImageContainer">
                    <img
                      src="https://static.vecteezy.com/system/resources/previews/014/576/653/original/airplane-icon-in-blue-circle-png.png"
                      alt="integrationImg"
                      style={{ width: 34 }}
                    />
                  </div>
                  <Tag color={creds?.valid ? "green" : "red"}>
                    {creds?.valid || "Not Valid"}
                  </Tag>
                </div>
                <div className="CardDescription">
                  <Typography
                    variant="h6"
                    style={{ color: colorPicker("neutral.800") }}
                  >
                    {creds?.portal_id || creds?.pan}{" "}
                    <Badge dot>
                      {creds.portal_id ? (
                        <EditOutlined />
                      ) : (
                        <PlusOutlined
                          style={{
                            fontSize: 20,
                            color: colorPicker("neutral.700"),
                          }}
                        />
                      )}
                    </Badge>
                  </Typography>

                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Fast and reliable approach for reconcilation
                  </Typography>
                </div>
              </div>
            );
          })}

          <Modal
            title="Airline Integration"
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={false}
            width={500}
          >
            <AddAirIntegration
              onSuccess={handleSuccess}
              type="AIRLINE"
              data={cardData}
              editMode={cardData && cardData?.portal_id === null ? false : true}
            />
          </Modal>
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          {/* <AgTableClient
            //@ts-ignore
            rowData={props.data}
            columnDefs={initialColDefs}
            autoResize
          /> */}
          <AirlineCredentials componentType />
        </div>
      )}
    </div>
  );
}
