import { Input } from "antd";
import Typography from "../Typography";

export default function TextField(props: any) {
  return (
    <div className="TextField" style={{ width: "100%" }}>
      {props.label ? (
        <div className="Title">
          <Typography
            style={{
              marginBottom: 2,
              ...(props.labelColor ? { color: props.labelColor } : {}),
            }}
            variant={props.variant || "caption"}
            weight={400}
          >
            {props.label}
          </Typography>
        </div>
      ) : null}
      <Input
        placeholder={props.placeholder}
        style={{
          height: props.height || 48,
          width: props.width || "100%",
          ...props.style,
        }}
        defaultValue={props.defaultValue}
        value={props.defaultValue}
        name={props.name}
        suffix={props.suffix}
        prefix={props.prefix}
        onChange={props.onChange && props.onChange}
        type={props.type}
        disabled={props.disabled}
        onPressEnter={props.onPressEnter}
      />
    </div>
  );
}
