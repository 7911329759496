import Typography from "app/shared/Typography";
import "./AddIntegration.scss";
import TextField from "app/shared/TextField";
import { Button, Select, Tag, message } from "antd";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import connectLottie from "static/lottie/connect.json";
import {
  API_ENDPOINT_EMAIL_CREDENTIAL_MAP,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
} from "app/scenes/Credentials/credentials.constants";
import { apiGet, apiPost } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: connectLottie,
};

const integrationMap: any = {
  GST: {
    logo: "https://www.indiapost.gov.in/_layouts/15/images/DOP.Portal.UILayer/Emblem_of_India.svg",
    title: "GST Portal Authentication",
  },
  AIRLINE: {
    logo: "https://static.vecteezy.com/system/resources/previews/014/576/653/original/airplane-icon-in-blue-circle-png.png",
    title: "Airline Authentication",
  },
  EMAIL: {
    logo: "https://cdn-icons-png.freepik.com/512/6711/6711567.png",
    title: "Email Authentication",
  },
};

export default function AddEmailIntegration(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [ssrEmail, setSSREmail] = useState("");
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const fetchEmailsForCluster = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST);
    if (response.status) {
      setEmailList(response.data);
    } else {
    }
  };

  useEffect(() => {
    fetchEmailsForCluster();
  }, []);
  useEffect(() => {
    console.log("payload props", props);
    if (props.editMode) {
      let emailPID: any = emailList.find(
        (item: any) => item.email === props.data?.ssr_email
      );
      console.log("found pid", emailPID);
      setSSREmail(emailPID?.email_login_pid);
      setEmail(props.data?.email);
    } else {
      setSSREmail("");
      setEmail("");
    }
  }, [props.data, emailList]);

  console.log("default data:", props.data);

  const isValidEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const handleUpdateEmailIntegration = async () => {
    if (!isValidEmail(email)) {
      messageApi.error({
        type: "error",
        content: "Invalid Email",
      });
      return;
    }
    const response = await apiPost(API_ENDPOINT_EMAIL_CREDENTIAL_MAP, {
      ...props.data,
      email_login_pid: ssrEmail,
      ssr_email: email,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };
  return (
    <div className="AddIntegration">
      {isLoading ? (
        <>
          <Lottie options={lottieOptions} height={160} width={160} />
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.600") }}
          >
            *do not close this window, as we are processing your request
          </Typography>
        </>
      ) : (
        <>
          <div className="IntegrationImage">
            <img
              src={integrationMap.EMAIL.logo}
              alt="integrationImg"
              style={{ width: 44 }}
            />
            <Typography variant="h6" style={{ marginTop: 24 }}>
              {props.data?.name}
            </Typography>
            <Tag color="green" style={{ marginTop: 6 }}>
              PAN - {props.data?.pan}
            </Tag>
          </div>
          <div className="FormBody">
            {props.type === "EMAIL" ? (
              <div className="FormGroup">
                <Select
                  placeholder="Select Email Config"
                  defaultValue={ssrEmail}
                  value={ssrEmail}
                  style={{ width: "100%", height: 42 }}
                  options={emailList.map((emailItem: any, key: any) => {
                    return {
                      value: emailItem.email_login_pid,
                      label: emailItem.email,
                    };
                  })}
                  onChange={(data: string) => setSSREmail(data)}
                />
              </div>
            ) : null}
            <div className="FormGroup">
              <TextField
                placeholder="Email"
                style={{ height: 42 }}
                onChange={(e: any) => setEmail(e.target.value)}
                defaultValue={email}
              />
            </div>

            <div className="FormGroup">
              <Button
                size="large"
                style={{
                  width: "100%",
                  background: colorPicker("primary.700"),
                  color: "white",
                }}
                onClick={handleUpdateEmailIntegration}
              >
                {props.editMode ? "Update Integration" : "Authenticate"}
              </Button>
            </div>
          </div>{" "}
        </>
      )}
      {props.type === "GST" && !props.editMode ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Typography
            style={{ color: colorPicker("primary.700"), cursor: "pointer" }}
            variant="caption"
            onClick={props.showBulk}
          >
            Or setup bulk integrations
          </Typography>
        </div>
      ) : null}

      {contextHolder}
    </div>
  );
}
