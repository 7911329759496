import { Button, Input } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { useState } from "react";

export default function AppTourStart(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [otpGenerated, setOTPGenerated] = useState(false);

  const handleGenerateOTP = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOTPGenerated(true);
    }, 2000);
  };

  return (
    <div className="AppTourStart">
      <div style={{ padding: "12px 88px" }}>
        <Typography
          variant={"h4"}
          style={{ textAlign: "center", color: colorPicker("neutral.900") }}
        >
          Start the tour to experience Finkraft ✨
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            marginTop: 8,
            color: colorPicker("neutral.800"),
          }}
        >
          See how Finkraft helps your team align on a single source of truth and
          overcome data chaos for good.
        </Typography>
        {otpGenerated ? (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 24 }}
          >
            <Button style={{ marginRight: 12 }} type="primary">
              Take Tour With AI
            </Button>
            <Button>Take Self Tour</Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 48,
              justifyContent: "center",
            }}
          >
            <Input
              style={{ marginRight: 12, width: 300 }}
              placeholder="Email"
            />
            <Button
              style={{ marginRight: 12 }}
              type="primary"
              onClick={handleGenerateOTP}
              loading={isLoading}
            >
              Get OTP
            </Button>
          </div>
        )}
        <div style={{ marginTop: 48 }}>
          <Typography
            variant="caption"
            style={{ color: colorPicker("neutral.600"), textAlign: "center" }}
            weight={400}
          >
            Loved by enterprises and startups
          </Typography>
          <div style={{ marginTop: 12, display: "flex", alignItems: "center" }}>
            <img
              style={{ width: 88, marginRight: 24, opacity: 0.7 }}
              src="https://upload.wikimedia.org/wikipedia/commons/6/61/Makemytrip_logo.svg"
            />
            <img
              style={{ width: 54, marginRight: 24, opacity: 0.7 }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/LG_Logo_Light_gray.svg/1280px-LG_Logo_Light_gray.svg.png"
            />
            <img
              style={{ width: 148, marginRight: 24, opacity: 0.7 }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Bain_%26_Company_logo.svg/2560px-Bain_%26_Company_logo.svg.png"
            />

            <img
              style={{ width: 88, marginRight: 24, opacity: 0.7 }}
              src="https://wallpapers.com/images/hd/tata-group-logo-blue-background-9vd42blt9qbkojyn-9vd42blt9qbkojyn.png"
            />

            <img
              style={{ width: 88, marginRight: 24, opacity: 0.7 }}
              src="https://i.pinimg.com/originals/86/1a/7b/861a7b02329489054c4e03492c1bd095.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
