import { Button, Checkbox, Divider, Form, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";
import {
  API_ENDPOINT_SETTING_INVITE_SIGNUP,
  API_ENDPOINT_SETTING_VERIFY_INVITE,
} from "app/scenes/Settings/settings.constants";
import Loader from "app/shared/Loader";
import color from "theme/color";

type FieldType = {
  email?: string;
  password?: string;
  name?: string;
};

export default function WorkspaceInvite(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const [tokenValid, setTokenValid] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [inviteInfo, setInviteInfo] = useState<any>(null);
  let { inviteToken } = useParams();

  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== password2) {
      messageApi.error({
        type: "error",
        content: "Password does not match",
      });
      return;
    }
    setLoading(true);
    let payload = {
      token: inviteToken,
    };
    const response = await apiPost(API_ENDPOINT_SETTING_INVITE_SIGNUP, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const verifyInviteToken = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_SETTING_VERIFY_INVITE + "?token=" + inviteToken
    );
    if (response.status) {
      setTokenValid(true);
      setInviteInfo(response.data);
      setEmail(response.data.email);
    } else {
      messageApi.error({
        type: "error",
        content: response.message || "Password does not match",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyInviteToken();
  }, []);

  const handleJoinWorkspcae = async () => {
    if (inviteInfo.ifUserExist) {
      handleRegister();
    } else {
      navigate("/auth/invitation/register", {
        state: { inviteInfo: inviteInfo, token: inviteToken },
      });
    }
  };
  return (
    <div className="AuthForm">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="FormHeader">
            <Typography style={{ color: colorPicker("neutral.700") }}>
              Welcome,{" "}
              <span
                style={{ color: colorPicker("neutral.900"), fontWeight: 500 }}
              >
                {inviteInfo?.email}
              </span>
            </Typography>
            <Typography
              style={{
                color: colorPicker("neutral.950"),
                marginTop: 12,
              }}
              variant="h5"
            >
              You have been invited to join these workspaces
            </Typography>
          </div>
          <div
            className="FieldContainer"
            style={{ marginTop: 24, display: "flex", height: 160 }}
          >
            {inviteInfo.workspace.map((invite: any, key: any) => {
              return (
                <div className="WorkspaceCard">
                  <img
                    src="https://images.pexels.com/photos/6985001/pexels-photo-6985001.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="iconImg"
                  />
                  <Typography style={{ color: colorPicker("neutral.800") }}>
                    {invite.name}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div
            style={{
              marginTop: 48,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" size="large" onClick={handleJoinWorkspcae}>
              Join Workspace
            </Button>
          </div>
        </>
      )}
      {contextHolder}
    </div>
  );
}
