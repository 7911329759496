import AppIcon from "static/images/FinLogo.png";
import "./PrimaryNav.scss";
import {
  SettingOutlined,
  ReconciliationOutlined,
  LockFilled,
  ScanOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import MMTLogo from "static/images/logos/mmt_logo.svg";
import ClientIcon from "static/images/mmtLogo.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// {
//   "title": "Flight",
//   "icon": "FlightTakeoffRoundedIcon",
//   "subMenus": [
//     {
//       "title": "Booking vs Invoice",
//       "route": "/flights/bookingvsinvoice"
//     },
//     {
//       "title": "2B vs IRN",
//       "route": "/flights/invoicevs2b"
//     }
//   ],
//   "route": "/flights"
// },
// {
//   "title": "Credentials",
//   "icon": "KeyRoundedIcon",
//   "subMenus": [
//     {
//       "title": "GST Credentials",
//       "icon": "PercentRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/gst"
//     },
//     {
//       "title": "Airline Credentials",
//       "icon": "FlightTakeoffRoundedIcon",
//       "subMenus": [],
//       "route": "/credentials/airline"
//     },
//     {
//       "title": "Email Credentials",
//       "icon": "MailOutlined",
//       "subMenus": [],
//       "route": "/credentials/mail"
//     }
//   ],
//   "route": "/credentials"
// },

import { Avatar, Divider, Drawer, Dropdown, MenuProps } from "antd";
import Typography from "../Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import SubMenu from "./SubMenu";
import MenuList from "./MenuItems.json";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useRecoilState } from "recoil";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { userInfo } from "app/config/States/users";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import RocketLaneIcon from "static/icons/rocketlane.png";
import Lottie from "react-lottie";
import { colorPicker } from "app/utils/color.helper";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_GENERATE_MAGIC_LINK_ROCKETLANE } from "app/scenes/Onboarding/onboarding.constants";
import TourIcon from "@mui/icons-material/Tour";

import GroupIcon from "@mui/icons-material/Group";
import LuggageIcon from "@mui/icons-material/Luggage";
const menuIconMap: any = {
  TimelineRoundedIcon: <TimelineRoundedIcon style={{ fontSize: 28 }} />,
  KeyRoundedIcon: <KeyRoundedIcon style={{ fontSize: 28 }} />,
  QueryStatsRoundedIcon: <QueryStatsRoundedIcon style={{ fontSize: 28 }} />,
  NotificationsRoundedIcon: (
    <NotificationsRoundedIcon style={{ fontSize: 28 }} />
  ),
  ReconciliationOutlined: <ReconciliationOutlined style={{ fontSize: 28 }} />,
  FlightTakeoffRoundedIcon: (
    <FlightTakeoffRoundedIcon style={{ fontSize: 24 }} />
  ),
  PercentRoundedIcon: <PercentRoundedIcon style={{ fontSize: 24 }} />,
  MailOutlined: <MailOutlined style={{ fontSize: 24 }} />,
  DescriptionOutlinedIcon: <DescriptionOutlinedIcon style={{ fontSize: 24 }} />,
  SignalCellularAltOutlinedIcon: (
    <SignalCellularAltOutlinedIcon style={{ fontSize: 24 }} />
  ),
  TourIcon: <TourIcon style={{ fontSize: 24 }} />,
  LuggageIcon: <LuggageIcon style={{ fontSize: 22 }} />,
  WorkspacesIcon: <WorkspacesIcon style={{ fontSize: 22 }} />,
  GroupIcon: <GroupIcon style={{ fontSize: 22 }} />,
  AddToPhotosIcon: <AddToPhotosIcon style={{ fontSize: 22 }} />,
  HotelRoundedIcon: <HotelRoundedIcon style={{ fontSize: 22 }} />,
  HomeOutlinedIcon: <HomeOutlinedIcon style={{ fontSize: 22 }} />,
};

const settingSubMenu = [
  {
    title: "Members",
    icon: "GroupIcon",
    subMenus: [],
    route: "/settings/members",
  },
  {
    title: "Workspaces",
    icon: "WorkspacesIcon",
    subMenus: [],
    route: "/settings/workspace",
  },
];

export default function PrimaryNav(props: any) {
  const [activeMenu, setActiveMenu] = useState("");
  const [openNestedMenu, setOpenNestedMenu] = useState(false);
  const [subMenuItem, setSubMenuItem] = useState(null);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const location = useLocation();

  const handleOpenNestedMenu = (menuItem: any) => {
    setOpenNestedMenu(!openNestedMenu);
    props.openMenu && props.openMenu();
    setSubMenuItem(menuItem);
    setActiveMenu(menuItem.route);
  };
  const handleCloseNestedMenu = () => {
    setOpenNestedMenu(false);
    props.openMenu && props.openMenu();
  };
  const navigate = useNavigate();
  const handleOpenSubMenu = async (menu: any) => {
    setActiveMenu(menu.route);
    if (menu.length > 0) {
      setOpenNestedMenu(!openNestedMenu);
      props.openMenu && props.openMenu();
    } else {
      navigate(menu.route);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const bottomMenuItems: MenuProps = {
    items: [
      {
        key: "1",
        label: (
          <>
            <div className="UserAvatarInfoPrimaryNav">
              <Avatar style={{ backgroundColor: colorPicker("primary.700") }}>
                {userDetails?.email[0]?.toUpperCase()}
              </Avatar>
              <div className="UserInfo">
                <Typography variant="body2" weight={600}>
                  {userDetails?.name}
                </Typography>
                <Typography variant="xs" weight={400} style={{ color: "gray" }}>
                  {userDetails?.email}
                </Typography>
              </div>
            </div>
            <Divider style={{ margin: 0, marginTop: 8 }} />
          </>
        ),
        disabled: true,
      },
      {
        key: "2",
        label: <Link to={"/gamification/profile/"}>Profile</Link>,
      },
      {
        key: "4",
        danger: true,
        label: <div onClick={handleLogout}>Logout</div>,
      },
    ],
  };

  const checkIFRoutehasActive = (route: string) => {
    if (location.pathname.includes(route)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="PrimaryNav">
      <div className="NavigationController">
        <div className="TopNavigation">
          <div className="AppLogo">
            <img alt="appIcon" src={ClientIcon} />
          </div>
          <div className="MenuContainer">
            {MenuList.map((menuItem: any, key: any) => {
              return !userDetails?.is_admin &&
                (menuItem.title === "Dashboard" ||
                  menuItem.title === "Integration") ? null : (
                <div
                  className={
                    "MenuItem " +
                    (activeMenu === menuItem.route ||
                    checkIFRoutehasActive(menuItem.route)
                      ? " active"
                      : "")
                  }
                  onClick={() =>
                    menuItem.subMenus.length > 0
                      ? handleOpenNestedMenu(menuItem)
                      : handleOpenSubMenu(menuItem)
                  }
                >
                  {menuIconMap[menuItem.icon]}
                  <Typography
                    variant="xs"
                    style={{
                      color:
                        activeMenu === menuItem.route ||
                        checkIFRoutehasActive(menuItem.route)
                          ? colorPicker("primary.700")
                          : "",
                    }}
                  >
                    {menuItem.title}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>

        <div className="BottomNavigation">
          {userDetails.is_admin ? (
            <>
              <div
                className={
                  "MenuItem " +
                  (activeMenu === "setting_workspace" ||
                  checkIFRoutehasActive("/settings/workspace")
                    ? " active"
                    : "")
                }
                onClick={() => {
                  setActiveMenu("setting_workspace");

                  navigate("/settings/workspace");
                }}
              >
                <BusinessCenterOutlinedIcon
                  onClick={() => {
                    // navigate("/settings/profile");
                    // handleOpenNestedMenu({
                    //   title: "Settings",
                    //   subMenus: settingSubMenu,
                    // });
                  }}
                  style={{ color: colorPicker("neutral.700") }}
                />
                <Typography
                  variant="xs"
                  style={{
                    color: (
                      activeMenu === "setting_workspace" ||
                      checkIFRoutehasActive("/settings/workspace")
                        ? " active"
                        : ""
                    )
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                  }}
                >
                  Workspaces
                </Typography>
              </div>
              <div
                className={
                  "MenuItem " +
                  (activeMenu === "setting_members" ||
                  checkIFRoutehasActive("/settings/members")
                    ? " active"
                    : "")
                }
                onClick={() => {
                  setActiveMenu("setting_members");
                  navigate("/settings/members");
                }}
              >
                <GroupAddOutlinedIcon
                  style={{ color: colorPicker("neutral.700") }}
                  onClick={() => {
                    // navigate("/settings/profile");
                    // handleOpenNestedMenu({
                    //   title: "Settings",
                    //   subMenus: settingSubMenu,
                    // });
                  }}
                />
                <Typography
                  variant="xs"
                  style={{
                    color: (
                      activeMenu === "setting_members" ||
                      checkIFRoutehasActive("/settings/members")
                        ? " active"
                        : ""
                    )
                      ? colorPicker("primary.700")
                      : colorPicker("neutral.700"),
                  }}
                >
                  Members
                </Typography>
              </div>
            </>
          ) : null}

          {userDetails?.email.includes("kgrp.in") ||
          userDetails?.email.includes("finkraft.ai") ? (
            <div
              className={
                "MenuItem " +
                (activeMenu === "settings_schema" ||
                checkIFRoutehasActive("/fa/schema/list")
                  ? " active"
                  : "")
              }
              onClick={() => {
                setActiveMenu("settings_schema");
                navigate("/fa/schema/list");
              }}
            >
              <SettingsOutlinedIcon
                style={{ color: colorPicker("neutral.700") }}
                onClick={() => {}}
              />
              <Typography
                variant="xs"
                style={{
                  color: (
                    activeMenu === "settings_schema" ||
                    checkIFRoutehasActive("/fa/schema/list")
                      ? " active"
                      : ""
                  )
                    ? colorPicker("primary.700")
                    : colorPicker("neutral.700"),
                }}
              >
                Settings
              </Typography>
            </div>
          ) : null}
          <div className="MenuItem" style={{ zIndex: 99999, marginBottom: 0 }}>
            <Dropdown
              menu={bottomMenuItems}
              placement="topRight"
              arrow
              overlayStyle={{ zIndex: 99999 }}
            >
              <Avatar
                size={36}
                style={{ backgroundColor: colorPicker("primary.700") }}
              >
                {userDetails?.email[0]?.toUpperCase()}
              </Avatar>
            </Dropdown>
          </div>

          <div
            className="MenuItem"
            style={{ marginBottom: 0, marginTop: 28, cursor: "auto" }}
          >
            <Typography
              variant="xs"
              style={{
                color: colorPicker("neutral.600"),
                fontSize: 10,
                marginBottom: 8,
              }}
            >
              Powered By
            </Typography>
            <img
              src={AppIcon}
              alt="FinkLogo"
              style={{ width: 48, opacity: 0.8, marginBottom: 12 }}
            />
          </div>
        </div>
      </div>
      <Drawer
        placement={"left"}
        closable={false}
        onClose={handleCloseNestedMenu}
        open={openNestedMenu}
        width={200}
        mask={false}
        maskClosable
        style={{ marginLeft: 80, padding: 0 }}
        zIndex={1}
      >
        <SubMenu
          onClose={handleCloseNestedMenu}
          menuItems={subMenuItem}
          iconMap={menuIconMap}
        />
      </Drawer>
    </div>
  );
}
