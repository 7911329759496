import { Button, ConfigProvider, Segmented, message } from "antd";
import React, { useState } from "react";
import "../../Onboarding.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import AddBusiness from "../AddBusiness";
import VerifyPAN from "../VerifyPAN";
import TextField from "app/shared/TextField";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE } from "../../onboarding.constants";
export default function AddWorkspace(props: any) {
  const [workspaceName, setWorkspaceName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);

  const handleCreateWorkspace = async () => {
    setLoading(true);
    const response: any = await apiPost(
      API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE,
      {
        name: workspaceName,
        is_active: "true",
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      props.goNext && props.goNext();
    } else {
      messageApi.error({
        type: "error",
        content: response.message || "Something went wrong",
      });
    }
    setLoading(false);
  };
  return (
    <div className="Greeting">
      <Typography
        variant="h4"
        style={{ color: colorPicker("neutral.800") }}
        weight={500}
      >
        Create your first workspace
      </Typography>
      <Typography style={{ color: colorPicker("neutral.700"), marginTop: 8 }}>
        Create your workspace to manage and start your journey with us.
      </Typography>

      <div
        style={{ marginTop: 40, display: "flex", justifyContent: "flex-start" }}
      >
        <div style={{ width: 400 }}>
          <TextField
            style={{ height: 40 }}
            placeholder="Workspace Name (eg Acme Corporation)"
            onChange={(e: any) => setWorkspaceName(e.target.value)}
          />
        </div>
        <Button
          size="large"
          style={{ marginLeft: 12 }}
          type="primary"
          loading={isLoading}
          onClick={handleCreateWorkspace}
        >
          Create
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
