import { ConfigProvider, Segmented } from "antd";
import React, { useState } from "react";
import "../../Onboarding.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import AddBusiness from "../AddBusiness";
import VerifyPAN from "../VerifyPAN";
export default function CredentialType(props: any) {
  const [viewType, setViewType] = useState("Credentials");
  return (
    <div className="Greeting">
      <Typography
        variant="h4"
        style={{ color: colorPicker("neutral.800"), marginBottom: 40 }}
        weight={500}
      >
        Start your journey with us
      </Typography>
      <ConfigProvider
        theme={{
          components: {
            Segmented: {
              itemSelectedBg: colorPicker("primary.50") + "80",
              itemColor: colorPicker("neutral.600"),
              itemSelectedColor: colorPicker("primary.700"),
            },
          },
        }}
      >
        <Segmented
          defaultValue={viewType}
          style={{ marginBottom: 8 }}
          onChange={(value) => setViewType(value)}
          options={["Credentials", "PAN"]}
          color="blue"
        />

        <div>
          {viewType === "PAN" ? (
            <VerifyPAN goNext={props.goNext} />
          ) : (
            <AddBusiness goNext={props.goNext} />
          )}
        </div>
      </ConfigProvider>
    </div>
  );
}
