import PageHeader from "app/shared/PageHeader";
import "../../FinkAnalytics.scss";
import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Tag,
} from "antd";
import SearchTable from "../SearchTable";
import { useEffect, useRef, useState } from "react";
import { AgTableClient } from "app/shared/AgTable";
import { PlusOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS,
  API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF,
  API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMA,
} from "../../finkanalytics.constants";
import Loader from "app/shared/Loader";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const dataTypeColor: any = {
  STRING: "#2980b9",
  NUMBER: "#27ae60",
  DATE: "#34495e",
};

const UI_FORMAT_TYPES: any = [
  { value: "FALSE", label: "None" },
  { value: "CHIP", label: "Chip" },
  { value: "CHIP_SHORT_TEXT", label: "Chip with Sort Text" },
  { value: "RATE", label: "Rate %" },
  { value: "ENTITY", label: "Entity" },
  { value: "AIRLINE", label: "Airline" },
  { value: "PERSON", label: "Person" },
  { value: "NUMBER", label: "Number" },

  { value: "AMOUNT", label: "Amount" },
  { value: "AMOUNT_BOLD", label: "Amount In Bold" },
  { value: "GSTIN_STATE", label: "GSTIN State" },
  { value: "DATE", label: "Date" },
  { value: "TAGS", label: "Tags" },
  { value: "ROUTE", label: "Route " },
  { value: "MATCH", label: "Match" },
  { value: "MATCH_COMPARE", label: "Match Compare" },
  { value: "MATCH_PRESENCE", label: "Check Key Existence " },
  { value: "MATCH_COMPARE_TEXT", label: "Compare Text" },
];
export default function FASetup(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<any[]>([]);
  const { schemaId } = useParams(); // Extract the id from the URL
  const [configData, setConfigData] = useState("");
  const [selectedCol, setSelectedCol] = useState<any>(null);
  const [schemaInfo, setSchemaInfo] = useState<any>(null);
  const gridRef: any = useRef();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (schemaId) {
      fetchEditDetails();
    } else {
      fetchTableData();
    }
  }, []);

  const fetchEditDetails = async () => {
    setLoading(true);
    const response = await apiGet(
      `${API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS}/${schemaId}`
    );
    setSchemaInfo(response.data);

    processMongoDefination(response.data?.state);
  };

  const agFilterTypeMap: any = {
    agTextColumnFilter: "STRING",
    agNumberColumnFilter: "NUMBER",
    agDateColumnFilter: "DATE",
  };
  const processMongoDefination = (CoulmnConfigData: any) => {
    let mainColDefs: any = [];
    let columnArray = CoulmnConfigData.columnDefs;
    columnArray.forEach((element: any) => {
      if (element.children && element.children.length > 0) {
        element.children.forEach((childElement: any) => {
          let getSourceKey = (CoulmnConfigData.columnMapping || []).find(
            (item: any) => item.destination_key === childElement.field
          );
          mainColDefs.push({
            id: childElement.field,
            source_key: getSourceKey?.source_key || childElement.field,
            destination_key: childElement.field,
            data_type: agFilterTypeMap[childElement.filter] || "STRING",
            header_name: childElement.headerName,
            ui_format_type: childElement.formatType || "NONE",
            enable_aggregation: childElement.enableValue,
            enable_row_group: childElement.enableRowGroup,
            enable_column_pivot: childElement.enablePivot,
            filter_type: childElement.filter,
            hide_column: childElement.hide,
            default_grouping: childElement.rowGroup,
            default_pinning: childElement.pinned || "NONE",
            column_group_show: childElement.columnGroupShow || "NONE",
            default_sorting: childElement.sort || "NONE",
            column_grouping: element.headerName,
            match_config_json: JSON.stringify(childElement.formatProps || {}),
            // match_config_json: "",
          });
        });
      } else {
        let findSourceKey = (CoulmnConfigData.columnMapping || []).find(
          (item: any) => item.destination_key === element.field
        );
        mainColDefs.push({
          id: element.field,
          source_key: findSourceKey?.source_key || element.field,
          destination_key: element.field,
          data_type: agFilterTypeMap[element.filter] || "STRING",
          header_name: element.headerName,
          ui_format_type: element.formatType || "NONE",
          enable_aggregation: element.enableValue,
          enable_row_group: element.enableRowGroup,
          enable_column_pivot: element.enablePivot,
          filter_type: element.filter,
          hide_column: element.hide,
          default_grouping: element.rowGroup,
          default_pinning: element.pinned || "NONE",
          column_group_show: element.columnGroupShow || "NONE",
          default_sorting: element.sort || "NONE",
          match_config_json: JSON.stringify(element.formatProps || {}),
        });
      }
    });
    setRowData(mainColDefs);
    setLoading(false);
  };

  const fetchTableData = async () => {
    setLoading(true);
    // const response = await apiGet(API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF);
    const response: any = [];
    setTableData(response);
    processRowData(response);
  };

  const processRowData = (columnList: any) => {
    let rowDataArray: any = [];

    let KeyValueData = Object.entries(columnList[0]);

    KeyValueData.forEach((item) => {
      rowDataArray.push({
        id: item[0],
        source_key: item[0],
        destination_key: item[0].replace(/[\s_-]/g, ""),
        data_type: (typeof item[1]).toUpperCase(),
        header_name: item[0],
        ui_format_type: "FALSE",
        enable_aggregation:
          (typeof item[1]).toUpperCase() === "NUMBER" ? true : false,
        enable_row_group: true,
        enable_column_pivot: false,
        filter_type: (typeof item[1]).toUpperCase(),
        hide_column: false,
        default_grouping: false,
        default_pinning: "FALSE",
        column_group_show: false,
        default_sorting: "FALSE",
        column_grouping: "",
        match_config_json: "",
      });
    });
    setRowData(rowDataArray);
    setLoading(false);
  };

  const handleUpdateSchemaMeta = (
    columnData: any,
    changeInfo: any,
    columnId: any
  ) => {
    // Create a copy of the row to be updated
    if (columnData[columnId] === changeInfo) {
      return; // If no change, exit early
    }
    const updatedRow = {
      ...columnData,
      [columnId]: changeInfo,
    };
    console.log("updated Row:", columnData, updatedRow);

    // Apply the update transaction
    gridRef.current &&
      gridRef.current.applyTransaction({ update: [updatedRow] });
  };

  const handleDeleteRow = (rowId: string) => {
    gridRef.current &&
      gridRef.current.applyTransaction({ remove: [{ id: rowId }] });
  };

  const processForDuplicateRows = (arrayData: any) => {
    const seen = new Set();
    return arrayData.filter((item: any) => {
      const keyValue = item["source_key"];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  };

  const columnDefs = [
    {
      field: "source_key",
      headerName: "Source Key",
      width: 300,
      suppressMenu: true,
      rowDrag: true,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Source Key"
          size="small"
          id={params?.data?.source_key}
          defaultValue={params?.data?.source_key}
          style={{
            fontSize: 11,

            width: 250,
          }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "source_key")
          }
        />
      ),
    },
    {
      field: "destination_key",
      headerName: "Destination Key",
      width: 240,
      suppressMenu: true,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Destination Key"
          size="small"
          id={params?.data?.destination_key}
          defaultValue={params?.data?.destination_key}
          style={{ fontSize: 11 }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "destination_key"
            )
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "destination_key"
            )
          }
        />
      ),
    },
    {
      field: "data_type",
      headerName: "Data Type",
      suppressMenu: true,
      width: 120,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.data_type}
          style={{ width: "100%" }}
          size="small"
          onChange={(selectedValue: any) => {
            handleUpdateSchemaMeta(params.data, selectedValue, "data_type");
          }}
          options={[
            { value: "NUMBER", label: "Number" },
            { value: "STRING", label: "Text" },
            { value: "DATE", label: "Date" },
          ]}
        />
      ),
    },
    {
      field: "header_name",
      headerName: "Header Name",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Header Name"
          size="small"
          id={params?.data?.header_name}
          defaultValue={params?.data?.header_name}
          style={{ fontSize: 11 }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "header_name")
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(params.data, e.target.value, "header_name")
          }
        />
      ),
    },
    {
      field: "ui_format_type",
      headerName: "UI Format Type",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.ui_format_type}
          style={{ width: "100%" }}
          size="small"
          options={UI_FORMAT_TYPES}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(params.data, selectedValue, "ui_format_type")
          }
        />
      ),
    },
    {
      field: "match_config_json",
      headerName: "UI Format Config",
      suppressMenu: true,
      width: 250,
      cellRenderer: (params: any) => (
        // Object.entries(params?.data?.["match_config_json"] || {})?.length >
        // 0 ? (
        //   <Button
        //     size="small"
        //     icon={<EditOutlined />}
        //     onClick={() => {
        //       setConfigData(
        //         JSON.stringify(params?.data?.["match_config_json"] || "")
        //       );
        //       setSelectedCol(params?.data);
        //       setShowModal(true);
        //     }}
        //   >
        //     Update Config
        //   </Button>
        // ) : (
        //   <Button
        //     size="small"
        //     icon={<PlusOutlined />}
        //     onClick={() => {
        //       setSelectedCol(params?.data);
        //       setShowModal(true);
        //     }}
        //   >
        //     Add Config
        //   </Button>
        // ),
        <Input
          defaultValue={params?.data?.match_config_json}
          onBlur={(e) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "match_config_json"
            )
          }
        />
      ),
    },
    {
      field: "column_grouping",
      headerName: "Column Groping",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => (
        <Input
          placeholder="Column Group Name"
          size="small"
          id={params.data?.column_grouping}
          value={params.data?.column_grouping}
          defaultValue={params?.data?.column_grouping}
          style={{ fontSize: 11 }}
          onPressEnter={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "column_grouping"
            )
          }
          onBlur={(e: any) =>
            handleUpdateSchemaMeta(
              params.data,
              e.target.value,
              "column_grouping"
            )
          }
        />
      ),
    },
    {
      field: "enable_row_group",
      headerName: "Row Groping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_row_group}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_row_group,
              "enable_row_group"
            )
          }
        />
      ),
    },

    {
      field: "enable_aggregation",
      headerName: "Aggregation",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_aggregation}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_aggregation,
              "enable_aggregation"
            )
          }
        />
      ),
    },
    {
      field: "enable_column_pivot",
      headerName: "Column Pivot",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.enable_column_pivot}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.enable_column_pivot,
              "enable_column_pivot"
            )
          }
        />
      ),
    },
    {
      field: "hide_column",
      headerName: "Hide Column",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.hide_column}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.hide_column,
              "hide_column"
            )
          }
        />
      ),
    },
    {
      field: "default_grouping",
      headerName: "Default Grouping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Switch
          checked={params?.data?.default_grouping}
          size="small"
          onChange={() =>
            handleUpdateSchemaMeta(
              params.data,
              !params.data?.default_grouping,
              "default_grouping"
            )
          }
        />
      ),
    },
    {
      field: "default_sorting",
      headerName: "Default Sorting",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.default_sorting}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "ASC", label: "Ascending" },
            { value: "DESC", label: "Descending" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "default_sorting"
            )
          }
        />
      ),
    },
    {
      field: "default_pinning",
      headerName: "Default Pinning",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.default_pinning}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "LEFT", label: "Left" },
            { value: "RIGHT", label: "Right" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "default_pinning"
            )
          }
        />
      ),
    },
    {
      field: "column_group_show",
      headerName: "Column Group Show",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue={params?.data?.column_group_show}
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "FALSE", label: "None" },
            { value: "open", label: "Open" },
            { value: "close", label: "Close" },
            { value: `["open","close"]`, label: "Both" },
          ]}
          onChange={(selectedValue: any) =>
            handleUpdateSchemaMeta(
              params.data,
              selectedValue,
              "column_group_show"
            )
          }
        />
      ),
    },

    {
      field: "action",
      headerName: "Action",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Button
          size="small"
          danger
          onClick={() => handleDeleteRow(params?.data?.id)}
        >
          DELETE
        </Button>
      ),
    },
  ];

  const handleDownload = () => {
    // Step 1: Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(rowData);

    // Step 2: Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Step 3: Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Step 4: Generate a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Step 5: Save the buffer as a file
    const data = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(data, "download.xlsx");
  };

  const handleSaveSchema = async () => {
    const payloadData =
      gridRef.current && gridRef.current.getFinalDataFromTable();
    console.log("schema info:", payloadData);
    // handleDownload();
    const payload = {
      state: payloadData,
      name: schemaInfo?.name,
      moduleId: schemaInfo?.moduleId,
    };

    const response = await apiPost(
      API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMA,
      payload
    );
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Awesome! your schema has been saved",
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Could not save schema",
      });
    }
  };

  const handleAddNewRow = () => {
    let newRowObj = {
      source_key: "source_key",
      destination_key: "destination_key",
      data_type: "STRING",
      header_name: "New Header",
      ui_format_type: "FALSE",
      enable_aggregation: false,
      enable_row_group: true,
      enable_column_pivot: false,
      filter_type: "agTextColumnFilter",
      hide_column: false,
      default_grouping: false,
      default_pinning: "FALSE",
      column_group_show: false,
      default_sorting: "FALSE",
      column_grouping: "",
      match_config_json: {},
    };
    // setRowData([newRowObj, ...rowData]);
    gridRef.current && gridRef.current.applyTransaction({ add: [newRowObj] });
  };
  return (
    <div className="FASetup">
      <div className="Header">
        <PageHeader
          title={"FA Setup - " + schemaInfo?.name}
          goBack
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Popconfirm
                placement="topLeft"
                title={"Saving Schema"}
                description={"Are you sure want to save this"}
                okText="Yes"
                cancelText="No"
                onConfirm={handleSaveSchema}
              >
                <Button
                  size="small"
                  type="primary"
                  icon={<SaveOutlined />}
                  // onClick={handleSaveSchema}
                >
                  Save Schema
                </Button>
              </Popconfirm>

              <Button
                size="small"
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginLeft: 24 }}
                onClick={handleAddNewRow}
              >
                Add New Row
              </Button>
            </div>
          }
        />
      </div>
      {/* <div className="SearchBoxContainer">
        <SearchTable
          placeholder="Search ZA Table"
          style={{ width: 400, height: 40 }}
          onChange={handleSearch}

        />
      </div> */}
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={processForDuplicateRows(rowData)}
            columnDefs={columnDefs}
            hideToolbar
            updateNewData={(data: any) => setRowData(data)}
            enableDragging
            dragColumn="source_key"
            showStatusBar
            ref={gridRef}
            disableLazy
            rowIdKey={"id"}
            useRowId
          />
        )}
      </div>

      <Modal
        title="UI Format Configuration"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <div>
          <Input
            defaultValue={configData}
            onBlur={(e) =>
              handleUpdateSchemaMeta(
                selectedCol,
                e.target.value,
                "match_config_json"
              )
            }
          />
          <Button
            size="small"
            style={{ marginTop: 24 }}
            type="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
