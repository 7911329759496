import { Button, Checkbox, Divider, Form, Spin, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_EMAIL_RESEND,
  API_ENDPOINT_AUTH_OAUTH_VERIFY_CODE,
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_GET_USER_INFO,
} from "../../auth.constants";

import { LoadingOutlined } from "@ant-design/icons";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";
import Loader from "app/shared/Loader";
import { textAlign } from "@mui/system";

type FieldType = {
  email?: string;
  password?: string;
};

export default function EmailSent(props: any) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.email) {
      navigate("/auth/signin");
    }
  }, []);

  const resendEmail = async () => {
    const response = await apiPost(API_ENDPOINT_AUTH_EMAIL_RESEND, {
      email: location.state.email,
      password: location.state.password,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
    }
    setLoading(true);
    setTimeout(() => {
      messageApi.success({
        type: "success",
        content: "Resent email verification",
      });
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="AuthForm">
      <div className="FieldContainer" style={{ marginTop: 0, height: "40vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src="https://img.freepik.com/premium-vector/email-messagingemail-marketing-campaignflat-design-icon-vector-illustration_183665-226.jpg"
            style={{ width: 200, marginBottom: 12 }}
          />
          <Typography
            style={{
              textAlign: "center",
              color: colorPicker("neutral.900"),
              marginBottom: 14,
            }}
            variant="h6"
          >
            We have sent you a verification link at{" "}
            <span style={{ color: colorPicker("primary.700") }}>
              {location?.state?.email}
            </span>
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: colorPicker("primary.500"),
              cursor: "pointer",
            }}
            onClick={resendEmail}
          >
            {isLoading ? "Sending.." : "Resend"}{" "}
            {isLoading ? (
              <Spin
                style={{ marginLeft: 12 }}
                indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
              />
            ) : null}
          </Typography>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
