import { Button, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import "./TableViewSelector.scss";
import { AgTableClient } from "../AgTable";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_TABLE_VIEW_ADD,
  API_ENDPOINT_TABLE_VIEW_LIST,
} from "global.constants";
import { pad } from "lodash";

export default function TableViewSelector(props) {
  const [selectedView, setSelectedView] = useState(null);
  const [viewList, setViewList] = useState([]);
  const [newColName, setNewColName] = useState("");
  const [colItems, setColItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [initialCols, setInitialCols] = useState([]);
  const [columnDefs, setColumnDef] = useState([]);
  const [viewName, setViewName] = useState("");
  const [backupCols, seBackupCols] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    fetchViewList();
  }, []);

  const processViewList = (viewList) => {
    return viewList.map((view) => {
      return { ...view, label: view.view_name, value: view.view_name };
    });
  };
  const fetchViewList = async () => {
    const response = await apiGet(API_ENDPOINT_TABLE_VIEW_LIST);
    if (response.status) {
      setViewList([
        { value: "DEFAULT", label: "DEFAULT" },
        ...processViewList(response.data),
      ]);
    } else {
      setViewList([{ value: "DEFAULT", label: "DEFAULT" }]);
    }
  };
  const processInitCols = (colList) => {
    let initColList = [];
    colList.forEach((element) => {
      initColList.push({
        label: element.headerName,
        value: element.field,
        ...element,
      });
    });

    setInitialCols(initColList);
  };
  useEffect(() => {
    if (props.defaultColumns) processInitCols(props.defaultColumns);
  }, [props.defaultColumns]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen("");
  };
  const handleCancel = () => {
    setIsModalOpen("");
  };

  const onChange = (value) => {
    let foundItem =
      value === "DEFAULT"
        ? "DEFAULT"
        : viewList.find((item) => item.view_name === value);
    props.onViewSelect && props.onViewSelect(foundItem || null);
  };

  const getMyOptions = () => {
    let options = [];
    console.log("initialCols,", initialCols);
    for (let i = 0; i <= initialCols.length - 1; i++) {
      options.push({
        label: initialCols[i].headerName,
        value: initialCols[i].field,
      });
    }
    console.log("options", options);
    return options;
  };

  const handleSaveView = async () => {
    if (!viewName) {
      messageApi.open({
        type: "error",
        content: "Enter view name",
      });
      return;
    }

    let payload = {
      view_id: props.id,
      view_name: viewName,
      columns: columnDefs,
    };
    console.log("payload", payload);
    return;
    const response = await apiPost(API_ENDPOINT_TABLE_VIEW_ADD, payload);
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Table view added",
      });
    } else {
      messageApi.open({
        type: "error",
        content: response.message,
      });
    }
    setIsModalOpen(false);
    fetchViewList();
  };

  const handleAddColumn = () => {
    if (!newColName || colItems.length === 0) {
      messageApi.open({
        type: "error",
        content: "Invalid Config",
      });
      return;
    }
    let columnObj = {
      headerName: newColName,
      showRowGroup: true,
      enableRowGroup: true,
    };
    if (colItems.length === 1) {
      columnObj.field = colItems[0].value;
      columnObj = { ...colItems[0], ...columnObj };
    }
    if (colItems.length > 1) {
      columnObj.cellRenderer = "agGroupCellRenderer";

      let childs = [];
      colItems.forEach((element) => {
        childs.push({
          headerName: element?.label,
          field: element?.field_table || element?.value,
          minWidth: 200,
          ...element,
        });
      });
      columnObj.children = childs;
    }
    setColumnDef([...columnDefs, columnObj]);
    seBackupCols([...backupCols, columnObj]);
    setNewColName("");
    setColItems([]);
  };

  return (
    <div className="TableViewSelector">
      <div className="InitialWidget">
        <div style={{ width: 160 }}>
          <Select
            placeholder="Select a view"
            onChange={onChange}
            options={viewList}
            style={{ width: "100%" }}
            size="small"
          />
        </div>

        <div style={{ marginLeft: 12 }}>
          <Button
            variant="contained"
            icon={<PlusOutlined />}
            size="small"
            onClick={() => setIsModalOpen("ADD")}
            type="primary"
          >
            Add View
          </Button>
        </div>

        {props.createdGroups && props.createdGroups.length > 0 && (
          <div style={{ marginLeft: 12 }}>
            <Button
              variant="contained"
              icon={<SaveOutlined />}
              size="small"
              onClick={() => setIsModalOpen("SAVE")}
            >
              Save View
            </Button>
          </div>
        )}
      </div>
      <Modal
        title="Create Table View"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={1000}
        zIndex={9999}
      >
        <div
          style={{
            marginBottom: 24,
            marginTop: 24,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <Input
              placeholder="Column Name"
              style={{ width: "100%" }}
              onChange={(e) => setNewColName(e.target.value)}
              value={newColName}
              size="small"
            />
          </div>
          <div style={{ marginLeft: 12, minWidth: 180 }}>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              size="small"
              value={colItems.map((item) => item.value)}
              placeholder="Please select"
              onChange={(items, fullObj) => setColItems(fullObj)}
              options={initialCols}
            />
          </div>

          <div style={{ marginLeft: 12 }}>
            <Button type="primary" onClick={handleAddColumn} size="small">
              Add Column
            </Button>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: "260px", width: "100%" }}
        >
          <AgTableClient columnDefs={columnDefs} rowData={[]} hideToolbar />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <div>
            <Input
              placeholder="View Name"
              style={{ width: "100%" }}
              onChange={(e) => setViewName(e.target.value)}
              value={viewName}
              size="small"
            />
          </div>
          <div style={{ marginLeft: 12 }}>
            <Button
              type="primary"
              size="small"
              onClick={handleSaveView}
              icon={<SaveOutlined />}
            >
              Save View
            </Button>
          </div>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
