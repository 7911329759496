import PageHeader from "app/shared/PageHeader";
import "../../Integrations.scss";

import { Tabs, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
export default function AllIntegrations(props: any) {
  const countMissingPortalId = (props.data.air || []).reduce(
    (count: any, item: any) => {
      return item.portal_id === null ? count + 1 : count;
    },
    0
  );

  const countMissingSSREmails = (props.data.email || []).reduce(
    (count: any, item: any) => {
      return item.ssr_email === null ? count + 1 : count;
    },
    0
  );

  return (
    <div className="CardList">
      <div className="IntegrationCard" onClick={() => props.onSelect("2")}>
        <div className="CardTopContainer">
          <div className="ImageContainer">
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*9mkbjH3YUUGDSWk2UXFghw.png"
              alt="integrationImg"
              style={{ width: 38 }}
            />
          </div>
          {props.data && props.data.gst.length > 0 ? (
            <Tag color="green">{props.data.gst.length} Connected</Tag>
          ) : (
            <Tag>
              <LinkOutlined style={{ marginRight: 4 }} />
              Connect
            </Tag>
          )}
        </div>
        <div className="CardDescription">
          <Typography
            variant="h6"
            style={{ color: colorPicker("neutral.800") }}
          >
            GST Portal{" "}
          </Typography>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700") }}
          >
            Fast, reliable, secure and efficient way to extract your GST data
            for accurate reconcilation.
          </Typography>
        </div>
      </div>

      <div className="IntegrationCard" onClick={() => props.onSelect("3")}>
        <div className="CardTopContainer">
          <div className="ImageContainer">
            <img
              src="https://static.vecteezy.com/system/resources/previews/014/576/653/original/airplane-icon-in-blue-circle-png.png"
              alt="integrationImg"
              style={{ width: 34 }}
            />
          </div>
          {props.data && props.data.air.length > 0 ? (
            <Tag color={countMissingPortalId > 0 ? "red" : "green"}>
              {countMissingPortalId > 0
                ? countMissingPortalId
                : props.data.air.length}{" "}
              {countMissingPortalId > 0 ? "Update Required" : "Connected"}
            </Tag>
          ) : (
            <Tag>
              <LinkOutlined style={{ marginRight: 4 }} />
              Connect
            </Tag>
          )}
        </div>
        <div className="CardDescription">
          <Typography
            variant="h6"
            style={{ color: colorPicker("neutral.800") }}
          >
            Airlines{" "}
          </Typography>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700") }}
          >
            Fast, reliable, secure and efficient way to extract your GST data
            for accurate reconcilation.
          </Typography>
        </div>
      </div>

      <div className="IntegrationCard" onClick={() => props.onSelect("4")}>
        <div className="CardTopContainer">
          <div className="ImageContainer">
            <img
              src="https://cdn-icons-png.freepik.com/512/6711/6711567.png"
              alt="integrationImg"
              style={{ width: 34 }}
            />
          </div>
          {props.data && props.data.email?.length > 0 ? (
            <Tag color={countMissingSSREmails > 0 ? "red" : "green"}>
              {countMissingSSREmails > 0
                ? countMissingSSREmails
                : props.data.email.length}{" "}
              {countMissingSSREmails > 0 ? "Update Required" : "Connected"}
            </Tag>
          ) : (
            <Tag>
              <LinkOutlined style={{ marginRight: 4 }} />
              Connect
            </Tag>
          )}
        </div>
        <div className="CardDescription">
          <Typography
            variant="h6"
            style={{ color: colorPicker("neutral.800") }}
          >
            Email Service{" "}
          </Typography>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700") }}
          >
            Fast, reliable, secure and efficient way to extract your GST data
            for accurate reconcilation.
          </Typography>
        </div>
      </div>
    </div>
  );
}
