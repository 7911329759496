import AppLoading from "./AppLoadingLottie.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: AppLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default function AppLoader(props: any) {
  return <Lottie options={defaultOptions} height={220} width={220} />;
}
