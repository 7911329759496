import PageHeader from "app/shared/PageHeader";
import "../../Integrations.scss";

import { Badge, Button, Empty, Modal, Segmented, Spin, Tabs, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { PlusOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import EmptyState from "app/shared/EmptyState";
import { useRef, useState } from "react";
import AddIntegration from "../AddIntegration";
import { AgTableClient } from "app/shared/AgTable";
import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
import ValidateCredentials from "app/scenes/Onboarding/components/ValidateCredentials";
import GSTCredentials from "app/scenes/Credentials/components/GSTCredentials";

const initialColDefs = [
  {
    field: "pan",
    headerName: "PAN",
    enableRowGroup: true,
    minWidth: 200,
  },
  {
    field: "gstin",
    headerName: "GSTIN",
    enableRowGroup: true,
    minWidth: 200,
  },
  {
    field: "username",
    headerName: "Username",
    enableRowGroup: true,
    minWidth: 200,
    editable: true,
  },

  {
    field: "password",
    headerName: "Password",
    enableRowGroup: true,
    minWidth: 200,
    editable: true,
  },
];

const tagColorMap: any = {
  INVALID: "red",
  ACTIVE: "green",
  PENDING: "orange",
};

export default function GSTIntegration(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Table");
  const [integrationType, setIntegrationType] = useState("SINGLE");
  const [bulkIntegrationData, setBulkIntegrationData] = useState(null);
  const [showBulkValidate, setShowBulkValidate] = useState(false);
  const [cardData, setCardData] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const gstGridRef: any = useRef();
  const handleSuccess = () => {
    setShowModal(false);
    props.loadData();
  };

  const handleBulkSuccess = () => {
    setShowBulkValidate(false);
    setShowModal(false);
    setBulkIntegrationData(null);
    setIntegrationType("SINGLE");
    props.loadData && props.loadData();
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: "12px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Segmented
          options={["Table", "Tile"]}
          onChange={(value) => setActiveTab(value)}
        />
        <Button
          type="primary"
          size="small"
          icon={<PlusOutlined />}
          onClick={() => {
            setCardData(null);
            setShowModal(true);
          }}
          style={{ marginRight: 24 }}
        >
          Add Credentials
        </Button>
      </div>
      {activeTab === "Tile" ? (
        <div className="CardList">
          <div
            className="IntegrationCard cardAdd"
            onClick={() => {
              setCardData(null);
              setShowModal(true);
            }}
          >
            <PlusOutlined
              style={{ fontSize: 44, color: colorPicker("neutral.700") }}
            />
          </div>

          {props.data.map((creds: any, key: any) => {
            return (
              <div
                className="IntegrationCard"
                key={key}
                onClick={() => {
                  setCardData(creds);
                  setShowModal(true);
                  setEditMode(true);
                }}
              >
                <div className="CardTopContainer">
                  <div className="ImageContainer">
                    <img
                      src="https://miro.medium.com/v2/resize:fit:1400/1*9mkbjH3YUUGDSWk2UXFghw.png"
                      alt="integrationImg"
                    />
                  </div>
                  <Tag color={tagColorMap[creds.status]}>
                    {creds.status === "PENDING" ? (
                      <Spin
                        style={{ marginRight: 6 }}
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 14, color: "#d46b08" }}
                            spin
                          />
                        }
                      />
                    ) : null}
                    {creds.status === "PENDING" ? "Processing" : creds.status}
                  </Tag>
                  {/* <Tag color={tagColorMap[creds.status]}>{creds.status}</Tag> */}
                </div>
                <div className="CardDescription">
                  <Typography
                    variant="h6"
                    style={{ color: colorPicker("neutral.800") }}
                  >
                    {creds?.username || creds?.gstin}{" "}
                    {creds.status !== "ACTIVE" ? (
                      <Badge dot>
                        {creds.username ? (
                          <EditOutlined />
                        ) : (
                          <PlusOutlined
                            style={{
                              fontSize: 20,
                              color: colorPicker("neutral.700"),
                            }}
                          />
                        )}
                      </Badge>
                    ) : null}
                  </Typography>

                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Fast and reliable approach for reconcilation
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          {/* <AgTableClient
            //@ts-ignore
            rowData={props.data}
            columnDefs={initialColDefs}
            autoResize
          /> */}
          <GSTCredentials ref={gstGridRef} componentType />
        </div>
      )}
      <Modal
        title="GST Portal Integration"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={integrationType === "SINGLE" ? 500 : 1000}
      >
        {integrationType === "SINGLE" ? (
          <AddIntegration
            onSuccess={handleSuccess}
            type="GST"
            showBulk={() => setIntegrationType("BULK")}
            data={cardData}
            editMode={cardData && cardData?.username !== null ? true : false}
          />
        ) : bulkIntegrationData === null ? (
          <AddBusiness
            goNext={(gstinData: any) => setBulkIntegrationData(gstinData)}
            goBack={() => setIntegrationType("SINGLE")}
          />
        ) : (
          <div style={{ height: 400 }}>
            <ValidateCredentials
              tableData={bulkIntegrationData}
              onSuccess={handleBulkSuccess}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
