import { useNavigate } from "react-router-dom";
import "./PageNotFound.scss";
export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="PageNotFound">
      <div className="ErrorMessage">404</div>
      <small>
        Oops look's like page you are looking for does not exist...
        <a onClick={() => navigate(-1)} href="#">
          Go Back
        </a>
      </small>
    </div>
  );
}
