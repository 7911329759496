import { Avatar, Badge, Input, Popover, Tag, message } from "antd";
import FinkAvatar from "static/images/FinkAvatar.png";
import "./FinkAI.scss";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import Typography from "../Typography";
import { v4 as uuidv4 } from "uuid";
import loadingDot from "static/lottie/dotLoading.json";
import Lottie from "react-lottie";
import SpeechToText from "./SpeechToText";
//@ts-ignore
import SureAudio from "static/audio/sure.mp3";
//@ts-ignore
import GotItAudio from "static/audio/goit.mp3";
//@ts-ignore
import OfcourseAudio from "static/audio/ofcourse.mp3";
//@ts-ignore
import HereyouGoAudio from "static/audio/hereyougo.mp3";
// import failedResponseAudio from "static/audio";

const finkPositiveResponse = [
  SureAudio,
  HereyouGoAudio,
  GotItAudio,
  OfcourseAudio,
];

export default function FinkAI(props: any) {
  const [showChat, setShowChat] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [defs, setDefs] = useState<any[]>([]);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const API_KEY = "sk-proj-AjF0eNKiecIoUqDU7fZ6T3BlbkFJOQduxJajpdVjuDSA8v2j";
  const API_ENDPOINT = "https://api.openai.com/v1/chat/completions";
  const [chatInProgress, setChatInProgress] = useState("");
  const [currentFilters, setCurrentFilters] = useState({
    groupModel: [],
    filterModel: {},
    sortModel: [],
  });

  useEffect(() => {
    getProcessDefinations();
  }, [props.columnDefs]);
  const getProcessDefinations = () => {
    let newArray: any = [];
    (props.columnDefs || []).forEach((columnData: any) => {
      newArray.push({
        field: columnData.field,
        headerName: columnData.headerName,
      });
    });
    setDefs(newArray);
  };

  const generateText = async (userInput: string) => {
    let randomNumber = Math.floor(Math.random() * 4) + 1;
    console.log("usercommand is", userInput);
    setTimeout(() => {
      const audio = new Audio(finkPositiveResponse[randomNumber - 1]);
      if (audio) audio.play();
    }, 500);

    let chatId = uuidv4();
    setChatHistory((chats) => [
      ...chats,
      ...[
        { role: "USER", text: userInput, id: uuidv4() },
        { role: "FINK", text: "", id: chatId },
      ],
    ]);

    setChatInProgress(chatId);
    setLoading(true);

    let userInputQuery = `let columnDefs=${getProcessDefinations()} consider above as column def for ag grid client side if I ask you to dynamically filter the table with query "${userInput}" give me only json no code example or explanation only json which I can use to set using default ag grid function to group/filter/sort in format of json which should match as mentioned here { "groupModel":[], "filterModel":{}, "sortModel":[] } as of now my current filter object for the table  is ${JSON.stringify(
      currentFilters
    )} if user is asking something like "can you also sort by total gst amount" then in that case append the new filter with preivous filter data in order to make more intuitive`;
    setInputPrompt("");

    const response: any = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-4o",
        messages: [{ role: "user", content: userInputQuery }],
        response_format: { type: "json_object" },

        temperature: 0.7,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const respData = await response.json();
    console.log("response data", respData);
    if (respData) {
      //   setChatHistory((chats) => [
      //     ...chats,
      //     {
      //       role: "FINK",
      //       text: respData.choices[0].message.content,
      //       loaded: false,
      //     },
      //   ]);
      let gptResponse = respData.choices[0].message.content;
      let cleanString = gptResponse
        .replace(/```json\n/, "")
        .replace(/\n```/, "");

      console.log("ag command", JSON.parse(cleanString));
      props.onResponse && props.onResponse(JSON.parse(cleanString));
      setCurrentFilters(JSON.parse(cleanString));
    }
    setLoading(false);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingDot,
  };

  console.log("reveived props", props);
  return (
    <div className="FinkAI">
      <div className="InitButton" onClick={() => setShowChat(!showChat)}>
        {!showChat ? (
          <>
            <Tag
              color={colorPicker("primary.600")}
              style={{ borderRadius: 25 }}
            >
              <span className="wave-emoji">👋</span> Hi, I am Fink AI
            </Tag>

            <Avatar
              style={{
                backgroundColor: "white",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                border: `2px solid ${colorPicker("primary.600")}`,
              }}
              size={42}
              src={<img src={FinkAvatar} alt="avatar" />}
            />
          </>
        ) : (
          <Avatar
            style={{
              backgroundColor: colorPicker("primary.700"),
              boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            }}
            size={34}
            icon={<CloseOutlined style={{ color: "white", fontSize: 20 }} />}
          />
        )}
      </div>

      <div className={"ChatContainer " + (showChat ? " active" : "")}>
        <div className="ChatHeader">
          <div className="HeaderLeft">
            <Popover
              content={
                <div>
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.200"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={112}
                    src={<img src={FinkAvatar} alt="avatar" />}
                  />
                </div>
              }
            >
              <Avatar
                style={{
                  backgroundColor: colorPicker("primary.200"),
                  boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                  cursor: "pointer",
                }}
                size={34}
                src={<img src={FinkAvatar} alt="avatar" />}
              />
            </Popover>
            <Typography
              style={{ marginLeft: 6, color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Fink AI
            </Typography>
          </div>
        </div>
        <div className="Chatbody">
          {chatHistory.length > 0 ? null : (
            <div className="ChatLeft">
              <div className="UserAvatar">
                <Avatar
                  style={{
                    backgroundColor: colorPicker("primary.200"),
                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                  }}
                  size={28}
                  src={<img src={FinkAvatar} alt="avatar" />}
                />
              </div>

              <div className="ChatContent">
                <div
                  className="TextContainer"
                  style={{ backgroundColor: colorPicker("primary.50") }}
                >
                  <Typography
                    variant="xs"
                    style={{ color: colorPicker("neutral.800") }}
                  >
                    Hi! I’m fink.ai im here to assist you. What you are looking
                    for?
                    <br />
                    <br />
                    Choose one of these popular filter for the current table.
                  </Typography>
                </div>
                <div className="ActionContainer">
                  {props.defaultActions.map((actionItem: any, key: any) => {
                    return (
                      <div
                        className="ActionItem"
                        style={{
                          border: `1px solid ${colorPicker("primary.700")}`,
                          cursor: "pointer",
                        }}
                        key={key}
                        onClick={() => {
                          setChatHistory([
                            ...chatHistory,
                            { role: "FINK", message: "Vizulatization done" },
                          ]);
                          props.onResponse &&
                            props.onResponse(actionItem.model);
                        }}
                      >
                        <Typography
                          variant="xs"
                          style={{ color: colorPicker("primary.700") }}
                        >
                          {actionItem.name}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {chatHistory.map((chat: any, key: any) => {
            return chat.role === "FINK" ? (
              <div className="ChatLeft" key={key}>
                <div className="UserAvatar">
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.200"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={28}
                    src={<img src={FinkAvatar} alt="avatar" />}
                  />
                </div>

                <div className="ChatContent">
                  <div
                    className="TextContainer"
                    style={{ backgroundColor: colorPicker("primary.50") }}
                  >
                    <Typography
                      variant="xs"
                      style={{ color: colorPicker("neutral.800") }}
                    >
                      {chatInProgress === chat.id && isLoading ? (
                        <Lottie
                          options={lottieOptions}
                          height={18}
                          width={26}
                        />
                      ) : (
                        <>
                          Visualization done!{" "}
                          {chatInProgress === chat.id ? (
                            <>
                              , not happy?
                              <span
                                style={{
                                  textDecoration: "underline",
                                  marginLeft: 4,
                                }}
                              >
                                Reset
                              </span>
                            </>
                          ) : null}
                        </>
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ChatRight" key={key}>
                <div className="ChatContent">
                  <div
                    className="TextContainer"
                    style={{ backgroundColor: colorPicker("neutral.100") }}
                  >
                    <Typography
                      variant="xs"
                      style={{ color: colorPicker("neutral.800") }}
                    >
                      {chat.text}
                    </Typography>
                  </div>
                </div>
                <div className="UserAvatar">
                  <Avatar
                    style={{
                      backgroundColor: colorPicker("primary.200"),
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                    size={28}
                  >
                    T
                  </Avatar>
                </div>
              </div>
            );
          })}
        </div>
        <div className="ChatInputContainer">
          {/* <SpeechToText /> */}
          <Input
            placeholder="Type..."
            suffix={
              <SendOutlined
                style={{ fontSize: 16, color: colorPicker("neutral.700") }}
                // onClick={generateText}
              />
            }
            prefix={
              <SpeechToText
                onSpeech={(text: string) => {
                  setInputPrompt(text);
                  //   generateText(text);
                }}
                currentText={inputPrompt}
                onInactivity={(text: string) => generateText(text)}
              />
            }
            onChange={(e) => setInputPrompt(e.target.value)}
            value={inputPrompt}
            onPressEnter={() => generateText(inputPrompt)}
            style={{
              backgroundColor: colorPicker("neutral.300"),
              border: "none",
              fontSize: 12,
              height: 32,
            }}
          />
        </div>
      </div>
    </div>
  );
}
