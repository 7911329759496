import { useEffect, useState } from "react";
import "./Onboarding.scss";
import { ConfigProvider, Steps } from "antd";
import Typography from "app/shared/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import SitRelax from "./components/SitRelax";
import ValidateCredentials from "./components/ValidateCredentials";
import { API_ENDPOINT_GET_USER_INFO } from "../Auth/auth.constants";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet } from "app/services/apiServices";
import Rocketlane from "./components/Rocketlane";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { colorPicker } from "app/utils/color.helper";
import FinkraftLogo from "static/images/FinLogo.png";
import CredentialType from "./components/CredentialType";
import AddWorkspace from "./components/AddWorkspace";

const intialSteps = [
  {
    title: "Add Credentials",
  },
  {
    title: "Validate Credentials",
  },
  {
    title: "That's all",
  },
];

export default function Onboarding(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [visitedStep, setVisitedStep] = useState(0);
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const [stepList, setStepList] = useState([
    {
      title: "Add Workspace",
    },
    {
      title: "Add Credentials",
    },
    {
      title: "Validate Credentials",
    },
    {
      title: "That's all",
    },
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabId = queryParams.get("tab");

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {}, []);
  const addHighlighter = (domClassName, targetArrayId = null) => {
    let domToAppend = document.getElementsByClassName(domClassName);

    let selctedDom =
      domToAppend.length > 1
        ? domToAppend[targetArrayId ? targetArrayId : 0]
        : domToAppend;
    var div = document.createElement("div");
    div.className = "domHighlighterCircle";
    div.id = "domHighlighterCircle";

    // Create and append three ripple divs inside the new div
    for (var i = 0; i < 3; i++) {
      var rippleDiv = document.createElement("div");
      rippleDiv.className = "domHighlighterCircle-ripple";
      div.appendChild(rippleDiv);
    }

    selctedDom.appendChild(div);
  };

  const renderForms = () => {
    switch (activeStep) {
      case 0:
        return (
          <AddWorkspace
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
          />
        );
      case 1:
        return (
          <CredentialType
            goNext={(jsonData) => {
              setActiveStep(activeStep + 1);
              setTableData(jsonData);
              setVisitedStep(activeStep + 1);
            }}
          />
        );

      case 2:
        return (
          <ValidateCredentials
            tableData={tableData}
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
          />
        );

      case 3:
        return (
          <SitRelax
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
          />
        );
    }
  };

  const radius = 10;

  const handleChangeStep = (newStepId) => {
    if (newStepId <= visitedStep) {
      setActiveStep(newStepId);
    }
  };

  const { Step } = Steps;

  return (
    <div className="Onboarding">
      <div className="StepContainer">
        <div>
          <div className="AppLogo">
            <img
              src={FinkraftLogo}
              alt="FinkLogo"
              onClick={() => navigate("/")}
            />
          </div>

          <ConfigProvider
            theme={{
              components: {
                Steps: {
                  /* here is your component tokens */
                },
              },
            }}
          >
            <Steps
              current={activeStep}
              direction="vertical"
              onChange={handleChangeStep}
            >
              {stepList.map((stepInfo, key) => {
                return (
                  <Step
                    title={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            color: colorPicker(
                              activeStep === key ? "primary.800" : "neutral.900"
                            ),
                            lineHeight: "20px",
                          }}
                          variant="xs"
                        >
                          Step {key + 1}
                        </Typography>
                        <Typography
                          style={{
                            color: colorPicker(
                              activeStep === key ? "primary.900" : "neutral.800"
                            ),
                            lineHeight: 1,
                          }}
                          weight={500}
                          variant="caption"
                        >
                          {stepInfo.title}
                        </Typography>
                      </div>
                    }
                  />
                );
              })}
            </Steps>
          </ConfigProvider>
        </div>
        <div
          style={{ paddingBottom: 24, display: "flex", alignItems: "center" }}
        >
          <RocketLaunchIcon style={{ color: colorPicker("primary.700") }} />
          <Typography
            style={{
              marginLeft: 6,
              color: colorPicker("neutral.900"),
              textDecoration: "underline",
              cursor: "pointer",
            }}
            weight={500}
          >
            Take a tour
          </Typography>
        </div>
      </div>
      <div className="ContentContainer">
        <div className="DetailContainer">{renderForms()}</div>
      </div>
    </div>
  );
}
