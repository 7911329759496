import ProtectedRoute from "app/utils/ProtectedRoute";
import Settings from "./Settings";
import WorkspaceSetting from "./components/WorkspaceSetting";
import MemberSetting from "./components/MemberSetting";

//eslint-disable-next-line
export default {
  path: "/settings/",
  element: (
    <ProtectedRoute>
      <Settings />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/settings/workspace",
      element: <WorkspaceSetting />,
      exact: true,
    },
    {
      path: "/settings/members",
      element: <MemberSetting />,
      exact: true,
    },
  ],
};
