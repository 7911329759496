import { Button, Checkbox, Divider, Form, message } from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import {
  API_ENDPOINT_AUTH_SIGNIN,
  API_ENDPOINT_AUTH_SIGNIN_OTP,
  API_ENDPOINT_AUTH_SIGNUP,
  API_ENDPOINT_GET_USER_INFO,
} from "../../auth.constants";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { colorPicker } from "app/utils/color.helper";

type FieldType = {
  email?: string;
  password?: string;
  name?: string;
};

export default function Signup(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== password2) {
      messageApi.error({
        type: "error",
        content: "Password does not match",
      });
      return;
    }
    setLoading(true);
    let payload = {
      email: email,
      password: password,
      password2: password2,
      name: name,
      orgname: orgName,
    };
    const response = await apiPost(API_ENDPOINT_AUTH_SIGNUP, payload);
    if (response.status) {
      navigate("/auth/email/sent", {
        state: { email: email, password: password },
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="AuthForm">
      <div className="FormHeader">
        <Typography style={{ color: colorPicker("neutral.700") }}>
          Welcome
        </Typography>
        <Typography
          style={{
            fontSize: 40,
            color: colorPicker("neutral.950"),
            marginTop: -6,
          }}
        >
          Sign up
        </Typography>
      </div>
      <div className="FieldContainer">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleRegister}
          autoComplete="off"
        >
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your orgnisation name!",
                },
              ]}
            >
              <TextField
                label="Orgnaisaiton Name"
                placeholder="Orgnaisaiton Name"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setOrgName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name!",
                },
              ]}
            >
              <TextField
                label="Full Name"
                placeholder="Full Name"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="FieldGroup">
            <Form.Item<FieldType>
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your email!",
                },
              ]}
            >
              <TextField
                label="Email"
                placeholder="Email"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="FieldGroup" style={{ marginTop: 28 }}>
            <Form.Item<FieldType>
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <TextField
                label="Password"
                placeholder="Password"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setPassword(e.target.value)}
                type="password"
              />
            </Form.Item>
          </div>
          <div className="FieldGroup" style={{ marginTop: 28 }}>
            <Form.Item<FieldType>
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your confirm password!",
                },
              ]}
            >
              <TextField
                label="Confirm Password"
                placeholder="Confirm Password"
                labelColor="var(--text-color-gray)"
                onChange={(e: any) => setPassword2(e.target.value)}
                type="password"
              />
            </Form.Item>
          </div>

          <div className="FieldGroup" style={{ marginTop: 38 }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              size="large"
              htmlType="submit"
              // disabled={!email || !password}
              loading={isLoading}
            >
              Sign in
            </Button>
          </div>
        </Form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="xs">
            Already have an account?{" "}
            <span
              style={{
                color: colorPicker("primary.700"),
                fontWeight: 500,
                cursor: "pointer",
              }}
              onClick={() => navigate("/auth/signin")}
            >
              Sign in
            </span>
          </Typography>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
