import { useRoutes } from "react-router-dom";
import RouteMap from "./app/config/routeConfig";

// import "theme/index.scss";

const Entry = () => {
  const routes = useRoutes([...RouteMap]);
  return routes;
};

export default Entry;
