import FinkAnalytics from "./FinkAnalytics";
import FASetup from "./components/FASetup";
import SchemaList from "./components/SchemaList";

//eslint-disable-next-line
export default {
  path: "/FA/",
  element: <FinkAnalytics />,
  strict: true,
  children: [
    {
      path: "/FA/schema/list",
      element: <SchemaList />,
      exact: true,
    },
    {
      path: "/FA/schema/edit/:schemaId",
      element: <FASetup />,
      exact: true,
    },
  ],
};
