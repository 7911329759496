import { FloatButton } from "antd";
import Typography from "app/shared/Typography";
import { RightCircleOutlined } from "@ant-design/icons";

export default function ReconDashboardDemo(props: any) {
  return (
    <div className="Greeting">
      <iframe
        title="hotel demo report"
        width="1024"
        style={{ border: 0, width: "100%" }}
        height="1060"
        src="https://analytics.zoho.in/open-view/103074000027759709/620a18a51770dd3d8400177d61e8f40f"
      ></iframe>

      <FloatButton
        onClick={props.goNext}
        style={{ marginRight: 24, width: 200 }}
        shape="square"
        type="primary"
        icon={
          <div style={{ display: "flex", width: "100%" }}>
            <Typography style={{ color: "white", marginRight: 6 }}>
              Next
            </Typography>
            <RightCircleOutlined />
          </div>
        }
      />
    </div>
  );
}
