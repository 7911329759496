import "./Reconcilation.scss";

import { Outlet } from "react-router-dom";

export default function Reconcilation(props: any) {
  return (
    <div className="Reconcilation">
      <Outlet />
    </div>
  );
}
