import { Button, Form, Input, Radio, Select, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import "./CreateEmail.scss";
import {
  MailOutlined,
  UserOutlined,
  SwapOutlined,
  KeyOutlined,
  GoogleOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import { useTheme } from "@emotion/react";
import { apiGet, apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_EMAIL_CREATE,
  API_ENDPOINT_EMAIL_GET_WORKSPACE_LIST,
} from "app/scenes/Credentials/credentials.constants";
import TextField from "app/shared/TextField";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

const ImapConfig: any = {
  Gmail: {
    host: "imap.gmail.com",
    port: 993,
  },
  Outlook: {
    host: "outlook.office365.com",
    port: 993,
  },
};
function CreateEmail(props: any) {
  const [imapHost, setImapHost] = useState("");
  const [imapPort, setImapPort] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [workspace, setWorkspace] = useState(
    "d1befcd7-877b-4544-838f-0e995cab5466"
  );
  const [gmailPassword, setGmailPassword] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);

  const handleCreateEmail = async () => {
    setLoading(true);
    const payload = {
      workspace_id: workspace,
      email: loginEmail,
      email_password: loginPassword,
      imap_host: imapHost,
      imap_port: parseInt(imapPort),
      gmail_app_password: gmailPassword,
    };

    const response = await apiPost(API_ENDPOINT_EMAIL_CREATE, payload);
    if (response.status) {
      messageApi.open({
        type: "success",
        content: response.message,
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleSelectServiceProvider = (serviceData: any) => {
    setServiceProvider(serviceData);
    setImapHost(ImapConfig[serviceData].host);
    setImapPort(ImapConfig[serviceData].port);
  };

  return (
    <div className="CreateEmail">
      <div className="SetupForm" style={{ width: "100%" }}>
        <div className="FormContent">
          <Typography.Paragraph style={{ fontWeight: 500 }}>
            Server Configuration {imapHost}
          </Typography.Paragraph>

          <div className="FormRow">
            <div className="FormGroup" style={{ width: "100%" }}>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Provider"
                onChange={handleSelectServiceProvider}
                options={[
                  {
                    value: "Gmail",
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <GoogleIcon style={{ fontSize: 16, marginRight: 6 }} />
                        <Typography>Gmail</Typography>
                      </div>
                    ),
                  },
                  {
                    value: "Outlook",
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MicrosoftIcon
                          style={{ fontSize: 16, marginRight: 6 }}
                        />
                        <Typography>Outlook</Typography>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          {/* <div
            className="FormRow"
            style={{ marginBottom: 12, marginTop: 12, width: "100%" }}
          >
            <Radio.Group
              value={configType}
              onChange={(e) => console.log("item is:", e)}
            >
              <Radio.Button value="IMAP">IMAP</Radio.Button>
              <Radio.Button value="POP">POP</Radio.Button>
            </Radio.Group>
          </div> */}
          <Form
            name="basic"
            onFinish={handleCreateEmail}
            fields={[
              {
                name: ["imap_host"],
                value: imapHost,
              },
              {
                name: ["imap_port"],
                value: imapPort,
              },
            ]}
          >
            <div className="FormRow" style={{ marginTop: 12 }}>
              <div className="FormGroup">
                <Form.Item
                  name="imap_host"
                  rules={[
                    { required: true, message: "Please input your IMAP host" },
                  ]}
                  style={{ width: "100%" }}
                  initialValue={imapHost}
                >
                  <TextField
                    placeholder="IMAP Host"
                    height={32}
                    onChange={(e: any) => setImapHost(e.target.value)}
                    prefix={<NodeIndexOutlined />}
                    defaultValue={imapHost}
                    name="imap_host"
                  />
                </Form.Item>
              </div>

              <div className="FormGroup">
                <Form.Item
                  name="imap_port"
                  rules={[
                    { required: true, message: "Please input your IMAP port" },
                  ]}
                  style={{ width: "100%" }}
                  initialValue={imapPort}
                >
                  <TextField
                    placeholder="IMAP Port"
                    height={32}
                    onChange={(e: any) => setImapPort(e.target.value)}
                    prefix={<NodeIndexOutlined />}
                    defaultValue={imapPort}
                    name="imap_port"
                  />
                </Form.Item>
              </div>
            </div>

            <Typography.Paragraph style={{ marginTop: 34, fontWeight: 500 }}>
              Login Credential
            </Typography.Paragraph>

            <div className="FormRow">
              <div className="FormGroup">
                <Form.Item
                  name="login_email"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email",
                    },
                  ]}
                >
                  <TextField
                    placeholder="Login Email"
                    height={32}
                    onChange={(e: any) => setLoginEmail(e.target.value)}
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="FormGroup">
                <Form.Item
                  name="login_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <TextField
                    placeholder="Login Password"
                    height={32}
                    onChange={(e: any) => setLoginPassword(e.target.value)}
                    prefix={<KeyOutlined />}
                  />
                </Form.Item>
              </div>
            </div>
            {serviceProvider ? (
              <div className="FormRow" style={{ marginTop: 12 }}>
                <div className="FormGroup">
                  <Form.Item
                    name="gmail_app_password"
                    style={{ width: "100%" }}
                  >
                    <TextField
                      placeholder={`${serviceProvider} App Password`}
                      height={32}
                      onChange={(e: any) => setGmailPassword(e.target.value)}
                      prefix={
                        serviceProvider === "Gmail" ? (
                          <GoogleOutlined style={{ fontSize: 14 }} />
                        ) : (
                          <MicrosoftIcon style={{ fontSize: 14 }} />
                        )
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 34,
              }}
            >
              <Button
                style={{ marginRight: 24 }}
                onClick={props.onClose}
                loading={isLoading}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Add Credential
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}

export default CreateEmail;
