import * as React from "react";
import { Navigate } from "react-router";

const ProtectedRoute = (props: any) => {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/auth/signin" />;
  }
  return props.children;
};

export default ProtectedRoute;
